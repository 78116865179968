import './App.css';
import '@fontsource/inter';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppLogin from './pages/login';
import Layout from './layouts/Layout';
import { ToastContainer } from 'react-toastify';
import Home from './pages/home';
import { AuthContextProvider } from './context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute';
import TeamExample from './pages/landing';
import Album from './components/Album';
import Pricing from './components/Pricing';
import Settings from './pages/settings';
import Dashboard from './pages/dashboard/dashboard';
import AddMember from './pages/member/addMember';
import AddMemberOtherCountry from './pages/member/addMemberOtherCountry';
import MemberList from './pages/member/memberList';
import LevelView from './pages/member/levelView';
import DirectMemberList from './pages/member/directMemberList';
import LiveBalanceRequest from './pages/liveBalanceRequest';
import Trcdeposit from './pages/trcdepo/Trcdeposit';
import About from './pages/About';
import Calls from './pages/Calls';
import Contact from './pages/Contact';
import Education from './pages/Education';
import MyProfile from './pages/MyAccounts/MyProfile';
import SupportSystem from './pages/support/SupportSystem';
import LiveBalanceRequestList from './pages/LiveBalanceRequestList';
import CriptoAddress from './pages/MyAccounts/CriptoAddress';
import ModifiedPassword from './pages/MyAccounts/ModifiedPassword';
import TrcWithdrawal from './pages/withdrawal/TrcWithdrawal';
import KycVerfication from './pages/MyAccounts/KycVerfication';
import MainSupport from './pages/support/MainSupport';
import AppSignUp from './pages/AppSignUp';
import OldSettleMent from './pages/OldSettleMent';
import Message from './pages/message/Message';
import OldSettleReport from './pages/OldSettleReport';
import OldSettleMentView from './pages/OldSettleMentView';
import Wallet from './pages/wallet/Wallet';
import InvestmentReport from './pages/report/InvestmentReport';
import WithdrawalReport from './pages/report/WithdrawalReport';

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<TeamExample />} />
          <Route path="/about" element={<About />} />
          <Route path="/calls" element={<Calls />} />
          <Route path="/education" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/album" element={<Album />} /> */}
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/login" element={<AppLogin />} />
          <Route path="/signup" element={<AppSignUp />} />
          {/* <Route path="" element={} /> */}
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/home" element={<Home />} />
            <Route path="/message" element={<Message />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/addmember" element={<AddMember />} />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/mycripto" element={<CriptoAddress />} />
            <Route path="/password" element={<ModifiedPassword />} />
            <Route path="/kyc" element={<KycVerfication />} />
            <Route
              path="/addmemberothercountry"
              element={<AddMemberOtherCountry />}
            />
            <Route path="/trcwithdrawal" element={<TrcWithdrawal />} />
            <Route path="/trcdeposit" element={<Trcdeposit />} />
            <Route path="/memberlist" element={<MemberList />} />
            <Route path="/directmemberlist" element={<DirectMemberList />} />
            <Route path="/levelview" element={<LevelView />} />
            <Route path="/supportsystem" element={<SupportSystem />} />
            <Route path="/oldsettlement" element={<OldSettleMent />} />
            <Route path="/oldsettlementview" element={<OldSettleMentView />} />
            <Route path="/oldsettlementreport" element={<OldSettleReport />} />
            <Route path="/support" element={<MainSupport />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/mywallet" element={<Wallet />} />
            <Route path="/investmentreport" element={<InvestmentReport />} />
            <Route path="/withdrawalreport" element={<WithdrawalReport />} />
            <Route
              path="/live-balance-request"
              element={<LiveBalanceRequest />}
            />
            <Route
              path="/live-balance-request-list"
              element={<LiveBalanceRequestList />}
            />
          </Route>
        </Routes>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
