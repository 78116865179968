import React, { useState, useEffect } from "react";
import { Box, Typography, Sheet, Table, TableCell, TableHead, TableRow } from "@mui/joy";
import { collection, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../firebase";

const MemberList = () => {
  const { user } = useAuth();
  const userId = user.uid;
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "members"));
        const membersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const filteredMembers = membersList.filter(
          (member) => member.createdBy === userId
        );
        setMembers(filteredMembers);
      } catch (error) {
        console.error("Error fetching members from Firebase:", error);
        toast.error("Error fetching members from Firebase!");
      }
    };

    fetchMembers();
  }, [userId]);

  return (
    <div style={{ backgroundColor: "#40474F", minHeight: "100vh" }}>
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: { xs: 2, sm: 3 } }}>
          <Typography
            level="h2"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: "white",
              fontSize: { xs: "1.5rem", sm: "2rem" },
              textAlign: { xs: "center", sm: "left" },
              mt: { xs: "20px", sm: "0px" },
            }}
          >
            List of Members
          </Typography>
          <Sheet
            variant="soft"
            sx={{
              pt: 1,
              borderRadius: "sm",
              maxWidth: { xs: "100%", sm: "90%" },
              marginX: "auto",
              overflow: "hidden",
            }}
          >
            <Box sx={{ overflowX: "auto" }}>
              {/* Table for larger screens */}
              <Table
                aria-label="member table"
                stripe="odd"
                hoverRow
                sx={{
                  minWidth: { xs: "500px", sm: "100%" },
                  "& th, & td": {
                    whiteSpace: "nowrap",
                    padding: { xs: "8px", sm: "8px 16px" },
                    fontSize: { xs: "0.75rem", sm: "0.875rem" },
                  },
                  "& thead th": {
                    backgroundColor: "background.level1",
                    fontWeight: "bold",
                  },
                  "& tbody tr:hover": {
                    backgroundColor: "action.hover",
                  },
                  "& tbody": {
                    bgcolor: "background.surface",
                  },
                }}
              >
                <thead>
                  <tr>
                    <th style={{ display: { xs: "none", sm: "table-cell" } }}>ID</th>
                    <th>Name</th>
                    <th style={{ display: { xs: "none", sm: "table-cell" } }}>Contact</th>
                    <th>Email</th>
                    <th>Country</th>
                    <th>State</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td style={{ display: { xs: "none", sm: "table-cell" } }}>
                        {member.id}
                      </td>
                      <td>{member.name}</td>
                      <td style={{ display: { xs: "none", sm: "table-cell" } }}>
                        {member.mobileNumber}
                      </td>
                      <td>{member.email}</td>
                      <td>{member.country}</td>
                      <td>{member.state}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>

            {/* Mobile View - Card layout */}
            <Box
              sx={{
                display: { xs: "block", sm: "none" },
                padding: "16px",
                borderRadius: "sm",
              }}
            >
              {members.map((member) => (
                <Box
                  key={member.id}
                  sx={{
                    backgroundColor: "background.surface",
                    borderRadius: "sm",
                    padding: "16px",
                    marginBottom: "16px",
                    boxShadow: 1,
                  }}
                >
                  <Typography level="h5" sx={{ fontWeight: "bold" }}>
                    {member.name}
                  </Typography>
                  <Typography sx={{ marginBottom: "8px" }}>
                    <strong>Email:</strong> {member.email}
                  </Typography>
                  <Typography sx={{ marginBottom: "8px" }}>
                    <strong>Country:</strong> {member.country}
                  </Typography>
                  <Typography sx={{ marginBottom: "8px" }}>
                    <strong>State:</strong> {member.state}
                  </Typography>
                  <Typography sx={{ marginBottom: "8px" }}>
                    <strong>Contact:</strong> {member.mobileNumber}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Sheet>
        </Box>
      </CssVarsProvider>
    </div>
  );
};

export default MemberList;
