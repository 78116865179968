import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { db } from '../firebase'; // Firebase configuration import
import { collection, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify';
import ModeIcon from '@mui/icons-material/Mode';
import { useNavigate } from 'react-router-dom';

function OldSettleMentView() {
  const navigate = useNavigate();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);
console.log('settlements', settlements);
  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'oldSettlement'));
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#40474F',
        minHeight: '100vh',
        padding: '1rem',
      }}
    >
      <Typography
        level="h2"
        style={{ color: '#FFFFFF' }}
        fontWeight="lg"
        sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
      >
        Old Settlements
      </Typography>
      <Button
        level="body-sm"
        style={{ color: '#FFFFFF' }}
        mb={2}
        size="lg"
        onClick={() => navigate('/oldsettlement')}
      >
        +
      </Button>
      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            backgroundColor: '#333',
            marginTop: '1rem',
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>ID</th>
              <th style={tableHeaderStyle}>Password</th>
              <th style={tableHeaderStyle}>Amount</th>
              <th style={tableHeaderStyle}>Balance</th>
              <th style={tableHeaderStyle}>Approved Amount</th>
              <th style={tableHeaderStyle}>Percentage</th>
              <th style={tableHeaderStyle}>Date</th>
              <th style={tableHeaderStyle}>Action</th>
            </tr>
          </thead>
          <tbody>
            {settlements.map((settlement) => (
              <tr key={settlement.id}>
                <td style={tableCellStyle}>{settlement.id}</td>
                <td style={tableCellStyle}>{settlement.password}</td>
                <td style={tableCellStyle}>{settlement.amount}</td>
                <td style={tableCellStyle}>{settlement.balance}</td>
                <td style={tableCellStyle}>{settlement.approvedamount}</td>
                <td style={tableCellStyle}>{settlement.percentage}</td>
                <td style={tableCellStyle}>
                  {new Date(
                    settlement.timestamp.seconds * 1000
                  ).toLocaleString()}
                </td>
                <td style={tableCellStyle}>
                  <Button>
                    <ModeIcon />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: '10px',
  borderBottom: '2px solid #555',
  textAlign: 'left',
  backgroundColor: '#555',
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #555',
  backgroundColor: '#bcbcbc',
  color: 'black',
};

export default OldSettleMentView;
