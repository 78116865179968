// import { createContext, useContext, useEffect, useState } from "react";
// import {
//   signInWithEmailAndPassword,
//   signOut,
//   onAuthStateChanged,
//   createUserWithEmailAndPassword,
//   updateProfile,
// } from "firebase/auth";
// import { auth, db } from "../firebase";
// import axios from "axios"; // Import Axios

// const UserContext = createContext();

// export const AuthContextProvider = ({ children }) => {
//   const [user, setUser] = useState(
//     JSON.parse(localStorage.getItem("user")) || "null"
//   );
//   const [token, setToken] = useState(sessionStorage.getItem("token") || null);
//   const [userMenu, setUserMenu] = useState(
//     JSON.parse(localStorage.getItem("userMenu"))
//   );

//   const signUp = (email, password) => {
//     return createUserWithEmailAndPassword(auth, email, password);
//   };

//   const signIn = async (userId, password) => {
//     const uid = userId;
//     try {
//       const response = await axios.post(
//         "http://ec2-13-201-97-103.ap-south-1.compute.amazonaws.com:8082/v1/user/login",
//         {
//           userId: userId,
//           password: password,
//           role: "user",
//         }
//       );
//       console.log("Login Response:", response.data);
//       if (response.status === 200) {
//         setUser(response.data.userDTO);
//         const userData = {
//           ...response.data.userDTO,
//         };
//         console.log("user--->", { ...response.data.userDTO, userUId: uid });
//         localStorage.setItem("user", JSON.stringify(userData));
//       }
//       setToken(true);
//       console.log("--->", response.data);
//       localStorage.setItem(
//         "userMenu",
//         JSON.stringify(response.data.menuDTOList)
//       );
//       // const signInResult = await signInWithEmailAndPassword(
//       //   auth,
//       //   email,
//       //   password
//       // );
//       // return signInResult;
//     } catch (error) {
//       console.error("Error signing in:", error.message);
//       throw error;
//     }
//   };

//   const logout = () => {
//     localStorage.setItem("userMenu", null);
//     localStorage.setItem("user", null);
//     setUser(null);
//     setUserMenu(null);
//     return signOut(auth);
//   };

//   const updateProfileInfo = async (profileData) => {
//     try {
//       await updateProfile(auth.currentUser, profileData);
//       setUser((prevUser) => ({
//         ...prevUser,
//         ...profileData,
//       }));
//     } catch (error) {
//       console.error("Error updating profile:", error.message);
//       throw error;
//     }
//   };

//   // useEffect(() => {
//   //   const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//   //     console.log(currentUser);
//   //     setToken(currentUser?.accessToken);
//   //     currentUser?.accessToken
//   //       ? sessionStorage.setItem("token", currentUser.accessToken)
//   //       : sessionStorage.removeItem("token");
//   //     setUser(currentUser);
//   //     return;
//   //   });
//   //   return unsubscribe;
//   // }, []);

//   return (
//     <UserContext.Provider
//       value={{
//         user,
//         token,
//         logout,
//         signIn,
//         signUp,
//         updateProfileInfo,
//         userMenu,
//       }}
//     >
//       {children}
//     </UserContext.Provider>
//   );
// };

// export const useAuth = () => useContext(UserContext);

// import { createContext, useContext, useEffect, useState } from 'react';
// import {
//   signInWithEmailAndPassword,
//   signOut,
//   onAuthStateChanged,
//   createUserWithEmailAndPassword,
// } from 'firebase/auth';
// import { auth, db } from '../firebase';
// import { doc, getDoc, collection } from 'firebase/firestore';

// const UserContext = createContext();

// export const AuthContextProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [hotel, setHotel] = useState(null);
//   const [token, setToken] = useState(sessionStorage.getItem('token') || null);

//   const signUp = (email, password) => {
//     return createUserWithEmailAndPassword(auth, email, password);
//   };

//   const signIn = async (email, password) => {
//     try {
//       const signInResult = await signInWithEmailAndPassword(auth, email, password);
//       console.log('Logged in successfully:', signInResult.user.accessToken);

//       if (signInResult.user.accessToken) {
//         setToken(signInResult.user.accessToken);
//         sessionStorage.setItem('token', signInResult.user.accessToken);
//         return signInResult.user;
//       }
//     } catch (error) {
//       console.error('Error signing in:', error.message);
//       throw error;
//     }
//   };

//   const logout = () => {
//     setToken(null);
//     setUser(null);
//     sessionStorage.removeItem('token');
//     return signOut(auth);
//   };

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       console.log('Auth state changed:', currentUser);
//       setToken(currentUser?.accessToken);
//       if (currentUser?.accessToken) {
//         sessionStorage.setItem('token', currentUser.accessToken);
//       } else {
//         sessionStorage.removeItem('token');
//       }
//       if (currentUser) {
//         const userDocRef = doc(db, 'users', currentUser.email);
//         const userDocSnapshot = await getDoc(userDocRef);
//         if (userDocSnapshot.exists()) {
//           setUser({
//             ...currentUser,
//             ...userDocSnapshot.data()
//           });
//         } else {
//           setUser(currentUser);
//         }
//       }
//     });
//     return unsubscribe;
//   }, []);

//   useEffect(() => {
//     const getMyHotel = async () => {
//       if (user?.hotelId) {
//         const docRef = doc(db, 'forexpro', user.hotelId);
//         const hotelDocSnapshot = await getDoc(docRef);
//         if (hotelDocSnapshot.exists()) {
//           setHotel({
//             id: user.hotelId,
//             ...hotelDocSnapshot.data(),
//           });
//         }
//       }
//     };
//     getMyHotel();
//   }, [user]);

//   return (
//     <UserContext.Provider value={{ user, hotel, token, logout, signIn, signUp }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

// export const useAuth = () => useContext(UserContext);

import { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null
  );
  const [hotel, setHotel] = useState(null);
  const [token, setToken] = useState(sessionStorage.getItem('token') || null);
  console.log('authuser', user);

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const signIn = async (email, password) => {
    try {
      const signInResult = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log('Logged in successfully:', signInResult.user.accessToken);

      if (signInResult.user.accessToken) {
        setToken(signInResult.user.accessToken);
        sessionStorage.setItem('token', signInResult.user.accessToken);
        localStorage.setItem('user', JSON.stringify(signInResult.user)); // Store user in localStorage
        return signInResult.user;
      }
    } catch (error) {
      console.error('Error signing in:', error.message);
      throw error;
    }
  };
  
  const logout = () => {
    setToken(null);
    setUser(null);
    sessionStorage.removeItem('token');
    localStorage.removeItem('user'); // Remove user from localStorage
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log('Auth state changed:', currentUser);
      setToken(currentUser?.accessToken);
      if (currentUser?.accessToken) {
        sessionStorage.setItem('token', currentUser.accessToken);
      } else {
        sessionStorage.removeItem('token');
      }
      if (currentUser) {
        const userDocRef = doc(db, 'users', currentUser.email);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          const fullUser = {
            ...currentUser,
            ...userDocSnapshot.data(),
          };
          setUser(fullUser);
          localStorage.setItem('user', JSON.stringify(fullUser)); // Store updated user in localStorage
        } else {
          setUser(currentUser);
          localStorage.setItem('user', JSON.stringify(currentUser)); // Store user in localStorage
        }
      } else {
        localStorage.removeItem('user'); // Remove user if signed out
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const getMyHotel = async () => {
      if (user?.hotelId) {
        const docRef = doc(db, 'forexpro', user.hotelId);
        const hotelDocSnapshot = await getDoc(docRef);
        if (hotelDocSnapshot.exists()) {
          setHotel({
            id: user.hotelId,
            ...hotelDocSnapshot.data(),
          });
        }
      }
    };
    getMyHotel();
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, hotel, token, logout, signIn, signUp }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => useContext(UserContext);
