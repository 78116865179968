// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   FormControl,
//   FormLabel,
//   Input,
//   Sheet,
//   Button,
//   IconButton,
// } from "@mui/joy";
// import { CssVarsProvider } from "@mui/joy/styles";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { db } from "../../firebase"; // Adjust path as needed
// import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
// import framesxTheme from "../../theme";
// import SupportSystem from "../support/SupportSystem";
// import { useAuth } from "../../context/AuthContext";
// import { toast } from "react-toastify";
// import { useEffect } from "react";

// function CriptoAddress() {
//   const {user} = useAuth()
//   const [cryptoAddress, setCryptoAddress] = useState("");
//   const [isEmailVerified, setIsEmailVerified] = useState(false);
//   const [isVerifiedClicked, setIsVerifiedClicked] = useState(false);
//   const [existingCryptoAddress, setExistingCryptoAddress] = useState(null);
//   const userUid = user.uid
//   const userEmail = user.email
//   console.log('userUid', existingCryptoAddress);
//   useEffect(() => {
//     // Fetch the current user's crypto address if it exists
//     const fetchCryptoAddress = async () => {
//       if (!userUid) return;

//       try {
//         const docRef = doc(db, "cryptoAddresses", userUid);
//         const docSnap = await getDoc(docRef);

//         if (docSnap.exists()) {
//           setExistingCryptoAddress(docSnap.data().cryptoAddress);
//         }
//       } catch (error) {
//         console.error("Error fetching crypto address:", error);
//         toast.error("Failed to fetch crypto address. Please try again.");
//       }
//     };

//     fetchCryptoAddress();
//   }, [userUid]);

//   const handleVerifyEmail = () => {
//     setIsVerifiedClicked(true);

//     // Simulate email verification process (Replace with actual API call)
//     setTimeout(() => {
//       const mockIsVerified = true; // Change to `false` to test unverified scenario
//       setIsEmailVerified(mockIsVerified);
//     }, 1000);
//   };

//   const handleUpload = async () => {
//     if (!cryptoAddress || !userEmail || !userUid) {
//       alert("Missing required data!");
//       return;
//     }

//     try {
//       const docRef = doc(db, "cryptoAddresses", userUid);
//       await setDoc(docRef, {
//         cryptoAddress,
//         email: userEmail,
//         uid: userUid,
//         createdAt: serverTimestamp(), // Automatically set the timestamp
//       });
//       toast.success("Data saved successfully ");
//         // Clear the input field after saving
//     setCryptoAddress("");
//     setIsVerifiedClicked(false); // Reset verification status
//     setIsEmailVerified(false); 
//     } catch (error) {
//       console.error("Error saving data:", error);
//       alert("Failed to save data. Try again.");
//     }
//   };

//   return (
//     <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
//       <Box
//         sx={{
//           padding: 2,
//           minHeight: "100vh",
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           backgroundColor: "#40474F",
//         }}
//       >
//         <Typography
//           level="h2"
//           style={{ color: "#FFFFFF" }}
//           fontWeight="lg"
//           sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
//         >
//           Crypto Address
//         </Typography>
//         <Typography
//           level="body-sm"
//           style={{ color: "#FFFFFF" }}
//           mb={2}
//           sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
//         >
//           Add Crypto Address
//         </Typography>
//         <Sheet
//           variant="soft"
//           sx={{
//             padding: { xs: 2, sm: 3 },
//             borderRadius: "sm",
//             maxWidth: 1500,
//             marginTop: 2,
//             marginX: "auto",
//           }}
//           bgcolor="neutral.softHoverBg"
//         >
//           {/* Crypto Address Input */}
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: { xs: "row", sm: "row" },
//               gap: 2,
//               marginBottom: 2,
//             }}
//           >
//             <FormControl sx={{ flex: 1 }}>
//               <FormLabel style={{ color: "black" }}>
//                 Your TRC20 Crypto Address
//               </FormLabel>
//               <Input
//                 placeholder="Enter crypto Address"
//                 value={cryptoAddress}
//                 onChange={(e) => setCryptoAddress(e.target.value)}
//               />
//             </FormControl>
//             <Button
//               size="sm"
//               variant="outlined"
//               color="primary"
//               sx={{
//                 backgroundColor: cryptoAddress ? "#ec1e1e94" : "gray",
//                 color: "#FFFFFF",
//                 width: { xs: "100%", sm: "10%" },
//                 cursor: cryptoAddress ? "pointer" : "not-allowed",
//               }}
//               disabled={!cryptoAddress}
//               onClick={handleVerifyEmail}
//             >
//               Verify Email
//             </Button>
//           </Box>

//           {/* Email Verification Status */}
//           {isVerifiedClicked && (
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 gap: 1,
//                 marginBottom: 2,
//               }}
//             >
//               {isEmailVerified ? (
//                 <>
//                   <Typography level="body1" style={{ color: "green" }}>
//                     Email Verified
//                   </Typography>
//                   <IconButton color="success" size="sm">
//                     <CheckCircleIcon />
//                   </IconButton>
//                 </>
//               ) : (
//                 <Typography level="body1" style={{ color: "red" }}>
//                   Email Not Verified. Please try again.
//                 </Typography>
//               )}
//             </Box>
//           )}

//           {/* Upload Button */}
//           {isEmailVerified && (
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 marginTop: 3,
//               }}
//             >
//               <Button
//                 variant="solid"
//                 sx={{
//                   backgroundColor: "green",
//                   color: "#FFFFFF",
//                   width: { xs: "100%", sm: "20%" },
//                 }}
//                 onClick={handleUpload}
//               >
//                 Upload
//               </Button>
//             </Box>
//           )}
//         </Sheet>
//       </Box>
//       <SupportSystem />
//     </CssVarsProvider>
//   );
// }

// export default CriptoAddress;



import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Button,
  IconButton,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { db } from "../../firebase"; // Adjust path as needed
import { doc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import framesxTheme from "../../theme";
import SupportSystem from "../support/SupportSystem";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";

function CriptoAddress() {
  const { user } = useAuth();
  const [cryptoAddress, setCryptoAddress] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isVerifiedClicked, setIsVerifiedClicked] = useState(false);
  const [existingCryptoAddress, setExistingCryptoAddress] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false); // Track if the input is in edit mode
  const userUid = user.uid;
  const userEmail = user.email;

  useEffect(() => {
    // Fetch the current user's crypto address if it exists
    const fetchCryptoAddress = async () => {
      if (!userUid) return;

      try {
        const docRef = doc(db, "cryptoAddresses", userUid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setExistingCryptoAddress(docSnap.data().cryptoAddress);
          setCryptoAddress(docSnap.data().cryptoAddress); // Set the state to the existing address
        }
      } catch (error) {
        console.error("Error fetching crypto address:", error);
        toast.error("Failed to fetch crypto address. Please try again.");
      }
    };

    fetchCryptoAddress();
  }, [userUid]);

  const handleVerifyEmail = () => {
    setIsVerifiedClicked(true);

    // Simulate email verification process (Replace with actual API call)
    setTimeout(() => {
      const mockIsVerified = true; // Change to `false` to test unverified scenario
      setIsEmailVerified(mockIsVerified);
    }, 1000);
  };

  const handleUpload = async () => {
    if (!cryptoAddress || !userEmail || !userUid) {
      alert("Missing required data!");
      return;
    }

    try {
      const docRef = doc(db, "cryptoAddresses", userUid);
      await setDoc(docRef, {
        cryptoAddress,
        email: userEmail,
        uid: userUid,
        createdAt: serverTimestamp(), // Automatically set the timestamp
      });
      toast.success("Data saved successfully");
      setIsEditMode(false); // Disable editing after saving
      setIsVerifiedClicked(false); // Reset verification status
      setIsEmailVerified(false); // Reset email verification status
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save data. Try again.");
    }
  };

  const handleUpdate = async () => {
    if (!cryptoAddress || !userEmail || !userUid) {
      alert("Missing required data!");
      return;
    }

    try {
      const docRef = doc(db, "cryptoAddresses", userUid);
      await setDoc(docRef, {
        cryptoAddress,
        email: userEmail,
        uid: userUid,
        createdAt: serverTimestamp(), // Update timestamp
      });
      toast.success("Crypto address updated successfully");
      setIsEditMode(false); // Disable editing after updating
    } catch (error) {
      console.error("Error updating data:", error);
      alert("Failed to update data. Try again.");
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#40474F",
        }}
      >
        <Typography
          level="h2"
          style={{ color: "#FFFFFF" }}
          fontWeight="lg"
          sx={{ fontSize: { xs: "1.5rem", md: "2rem" } }}
        >
          Crypto Address
        </Typography>
        <Typography
          level="body-sm"
          style={{ color: "#FFFFFF" }}
          mb={2}
          sx={{ fontSize: { xs: "0.875rem", md: "1rem" } }}
        >
          Add or Update your Crypto Address
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: { xs: 2, sm: 3 },
            borderRadius: "sm",
            maxWidth: 1500,
            marginTop: 2,
            marginX: "auto",
          }}
          bgcolor="neutral.softHoverBg"
        >
          {/* Crypto Address Input */}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row" },
              gap: 2,
              marginBottom: 2,
            }}
          >
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: "black" }}>
                Your TRC20 Crypto Address
              </FormLabel>
              <Input
                placeholder="Enter crypto Address"
                value={cryptoAddress}
                onChange={(e) => setCryptoAddress(e.target.value)}
                disabled={!isEditMode && existingCryptoAddress}
              />
            </FormControl>

            {/* Edit or Save/Update Button */}
            {existingCryptoAddress ? (
              isEditMode ? (
                <Button
                  size="sm"
                  variant="outlined"
                  color="primary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              ) : (
                <Button
                  size="sm"
                  variant="outlined"
                  color="primary"
                  onClick={() => setIsEditMode(true)}
                >
                  Edit
                </Button>
              )
            ) : (
              <Button
                size="sm"
                variant="outlined"
                color="primary"
                onClick={handleVerifyEmail}
              >
                Verify Email
              </Button>
            )}
          </Box>

          {/* Email Verification Status */}
          {isVerifiedClicked && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
              }}
            >
              {isEmailVerified ? (
                <>
                  <Typography level="body1" style={{ color: "green" }}>
                    Email Verified
                  </Typography>
                  <IconButton color="success" size="sm">
                    <CheckCircleIcon />
                  </IconButton>
                </>
              ) : (
                <Typography level="body1" style={{ color: "red" }}>
                  Email Not Verified. Please try again.
                </Typography>
              )}
            </Box>
          )}

          {/* Upload Button */}
          {!existingCryptoAddress && isEmailVerified && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 3,
              }}
            >
              <Button
                variant="solid"
                sx={{
                  backgroundColor: "green",
                  color: "#FFFFFF",
                  width: { xs: "100%", sm: "20%" },
                }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Box>
          )}
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default CriptoAddress;
