// import * as React from "react";
// import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
// import GlobalStyles from "@mui/joy/GlobalStyles";
// import CssBaseline from "@mui/joy/CssBaseline";
// import Box from "@mui/joy/Box";
// import Button from "@mui/joy/Button";
// import Checkbox from "@mui/joy/Checkbox";
// import Divider from "@mui/joy/Divider";
// import FormControl from "@mui/joy/FormControl";
// import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
// import IconButton from "@mui/joy/IconButton";
// import Link from "@mui/joy/Link";
// import Input from "@mui/joy/Input";
// import Typography from "@mui/joy/Typography";
// import Stack from "@mui/joy/Stack";
// import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
// import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
// import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
// import GoogleIcon from "../assets/icons/GoogleIcon";
// import { toast } from "react-toastify";
// import { useFormAction, useNavigate } from "react-router-dom";
// import { useAuth } from "../context/AuthContext";
// import { useForm } from 'react-hook-form';

// import bgImgWhite from "../assets/icons/InvestmentWhite.png";
// import bgImgBlack from "../assets/icons/InvestmentBlack.png";

// import logo from "../assets/icons/logo.png";
// import logo_name from "../assets/icons/BV (1).png";

// function ColorSchemeToggle(props) {
//   const { onClick, ...other } = props;
//   const { mode, setMode } = useColorScheme();
//   const [mounted, setMounted] = React.useState(false);
//   React.useEffect(() => {
//     setMounted(true);
//   }, []);
//   if (!mounted) {
//     return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
//   }
//   return (
//     <IconButton
//       id="toggle-mode"
//       size="sm"
//       variant="outlined"
//       color="neutral"
//       aria-label="toggle light/dark mode"
//       {...other}
//       onClick={(event) => {
//         if (mode === "light") {
//           setMode("dark");
//         } else {
//           setMode("light");
//         }
//         onClick?.(event);
//       }}
//     >
//       {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
//     </IconButton>
//   );
// }

// export default function AppLogin() {
//   const { signIn, user, token } = useAuth();
//   const navigate = useNavigate();

//   const {
//     register,
//     handleSubmit,
//     formState: { isSubmitting, isDirty, isValid, errors },
//   } = useForm({ mode: 'onChange' });

//   const onSubmit = async ({ email, password }) => {
//     try {
//       await signIn(email, password);
//       navigate('/home');
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   // React.useEffect(() => {
//   //   if (user && token) {
//   //     navigate("/home");
//   //   }
//   // });

//   // const handleLogin = async (event) => {
//   //   event.preventDefault();
//   //   const formElements = event.currentTarget.elements;
//   //   const data = {
//   //     userId: formElements.userId.value,
//   //     password: formElements.password.value,
//   //     persistent: formElements.persistent.checked,
//   //   };
//   //   await signIn(data.userId, data.password)
//   //     .then(() => {
//   //       toast.success("Sucessfully Logged in", {
//   //         position: "top-center",
//   //         autoClose: 600,
//   //         hideProgressBar: true,
//   //         closeOnClick: true,
//   //         pauseOnHover: true,
//   //         draggable: true,
//   //         progress: undefined,
//   //         theme: "colored",
//   //       });
//   //       navigate("/home");
//   //     })
//   //     .catch((err) => {
//   //       console.log("err", err);
//   //     });
//   // };

//   return (
//     <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
//       <CssBaseline />
//       <GlobalStyles
//         styles={{
//           ":root": {
//             "--Collapsed-breakpoint": "769px",
//             "--Cover-width": "50vw",
//             "--Form-maxWidth": "800px",
//             "--Transition-duration": "0.4s",
//           },
//         }}
//       />
//       <Box
//         sx={(theme) => ({
//           width:
//             "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
//           transition: "width var(--Transition-duration)",
//           transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
//           position: "relative",
//           zIndex: 1,
//           display: "flex",
//           justifyContent: "flex-end",
//           backdropFilter: "blur(12px)",
//           backgroundColor: "rgba(255 255 255 / 0.2)",
//           [theme.getColorSchemeSelector("dark")]: {
//             backgroundColor: "rgba(19 19 24 / 0.4)",
//           },
//         })}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             minHeight: "100dvh",
//             width:
//               "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
//             maxWidth: "100%",
//             px: 2,
//           }}
//         >
//           <Box
//             component="header"
//             sx={{
//               py: 3,
//               display: "flex",
//               alignItems: "left",
//               justifyContent: "space-between",
//             }}
//           >
//             <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
//               <img className="w-12 me-2" src={logo} />
//               <img className="h-10 me-2" src={logo_name} />
//               {/* <Typography variant="h6">ForexPro</Typography> */}
//             </Box>
//           </Box>
//           <Box
//             component="main"
//             sx={{
//               my: "auto",
//               py: 2,
//               pb: 5,
//               display: "flex",
//               flexDirection: "column",
//               gap: 2,
//               width: 400,
//               maxWidth: "100%",
//               mx: "auto",
//               borderRadius: "sm",
//               "& form": {
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: 2,
//               },
//               [`& .${formLabelClasses.asterisk}`]: {
//                 visibility: "hidden",
//               },
//             }}
//           >
//             {/* <Stack gap={4} sx={{ mb: 2 }}>
//               <Stack gap={1}>
//                 <Typography level="h3">Sign in</Typography>
//                 <Typography level="body-sm">
//                   New User ?{" "}
//                   <Link href="#replace-with-a-link" level="title-sm">
//                     Sign up!
//                   </Link>
//                 </Typography>
//               </Stack>
//               <Button
//                 variant="outlined"
//                 color="neutral"
//                 fullWidth
//                 startDecorator={<GoogleIcon />}
//               >
//                 Continue with Google
//               </Button>
//             </Stack> */}
//             {/* <Divider
//               sx={(theme) => ({
//                 [theme.getColorSchemeSelector("light")]: {
//                   color: { xs: "#FFF", md: "text.tertiary" },
//                   "--Divider-lineColor": {
//                     xs: "#FFF",
//                     md: "var(--joy-palette-divider)",
//                   },
//                 },
//               })}
//             >
//               or
//             </Divider> */}
//             <Stack gap={4} sx={{ mt: 2 }}>
//               <form onSubmit={handleSubmit(onSubmit)}>
//                 <FormControl >
//                   <FormLabel>User ID</FormLabel>
//                   <Input {...register('email')} type="email" name="email" />
//                 </FormControl>
//                 <FormControl >
//                   <FormLabel>Password</FormLabel>
//                   <Input
//                     {...register('password')}
//                     type="password"
//                     name="password"
//                   />
//                 </FormControl>
//                 <Stack gap={4} sx={{ mt: 2 }}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Checkbox size="sm" label="Remember me" name="persistent" />
//                     <Link level="title-sm" href="#replace-with-a-link">
//                       Forgot your password?
//                     </Link>
//                   </Box>
//                   <Button type="submit" fullWidth>
//                     Sign in
//                   </Button>
//                 </Stack>
//               </form>
//             </Stack>
//           </Box>
//           <Box component="footer" sx={{ py: 3 }}>
//             <Typography level="body-xs" textAlign="center">
//               © Microace Software {new Date().getFullYear()}
//             </Typography>
//           </Box>
//         </Box>
//       </Box>
//       <Box
//         sx={(theme) => ({
//           height: "100%",
//           position: "fixed",
//           right: 0,
//           top: 0,
//           bottom: 0,
//           left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
//           transition:
//             "background-image var(--Transition-duration), left var(--Transition-duration) !important",
//           transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
//           backgroundColor: "background.level1",
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//           backgroundImage: `url(${bgImgWhite})`,
//           [theme.getColorSchemeSelector("dark")]: {
//             backgroundImage: `url(${bgImgBlack})`,
//             // 'url(https://www.forextime.com/s3-static/Beginners-Guide/what_is_forex.jpg)',
//           },
//         })}
//       />
//     </CssVarsProvider>
//   );
// }

import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import IconButton from "@mui/joy/IconButton";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useForm } from "react-hook-form";
import bgImgWhite from "../assets/icons/new_img.jpg";
import bgImgBlack from "../assets/icons/new_img.jpg";
import logo from "../assets/icons/BLogo.png";
import logo_name from "../assets/icons/Header.png";
import { toast } from "react-toastify";

function ColorSchemeToggle(props) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
  }
  return (
    <IconButton
      size="sm"
      variant="outlined"
      color="neutral"
      aria-label="toggle light/dark mode"
      {...other}
      onClick={(event) => {
        setMode(mode === "light" ? "dark" : "light");
        onClick?.(event);
      }}
    >
      {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

export default function AppLogin() {
  const { signIn, user, token } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({ mode: "onChange" });

  const onSubmit = async ({ email, password }) => {
    try {
      await signIn(email, password);
      toast.success("Login successful! Redirecting...");
    } catch (err) {
      console.log("Login error:", err);
      toast.error("Invalid username or password. Please try again.");
    }
  };

  // Watch for user and token changes to navigate after successful login
  React.useEffect(() => {
    if (user && token) {
      navigate("/home");
    }
  }, [user, token, navigate]);

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Collapsed-breakpoint": "769px",
            "--Cover-width": "50vw",
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s",
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(12px)",
          backgroundColor: "#0A221F",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width:
              "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
            maxWidth: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
              <img className="w-12 me-2" src={logo} alt="Logo" />
              <img className="h-10 me-2" src={logo_name} alt="Logo Name" />
            </Box>
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <FormControl >
                <FormLabel style={{ color: 'white' }}>Name</FormLabel>
                <Input  type="text" />
              </FormControl> */}
              <FormControl>
                <FormLabel style={{ color: "white" }}>Email</FormLabel>
                <Input {...register("email")} type="email" />
              </FormControl>
              {/* <FormControl >
                <FormLabel style={{ color: 'white' }}>Enter OTP</FormLabel>
                <Input type="number" />
              </FormControl> */}
              <FormControl>
                <FormLabel style={{ color: "white" }}>Password</FormLabel>
                <Input {...register("password")} type="password" />
              </FormControl>
              <Stack gap={4} sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Link href="#replace-with-a-link" sx={{ marginLeft: "auto" }}>
                    Forgot your password?
                  </Link>
                </Box>
                {/* <FormControl >
                <FormLabel style={{ color: 'white' }}>Reference ID</FormLabel>
                <Input  type="text" />
              </FormControl> */}
                <Button
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                  style={{ color: "white" }}
                >
                  Login
                </Button>
              </Stack>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Microace Software {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${bgImgWhite})`,
          [theme.getColorSchemeSelector("dark")]: {
            backgroundImage: `url(${bgImgBlack})`,
          },
        })}
      />
    </CssVarsProvider>
  );
}
