import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Select,
  Option,
  // Modal,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../firebase";
import { useAuth } from "../context/AuthContext";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import SupportSystem from "./support/SupportSystem";

const LiveBalanceRequest = () => {
  // const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const { user } = useAuth();
  console.log("/*/*/*/*/*", user);
  // Define validation schema
  const [showModal, setShowModal] = useState(false);
  const validationSchema = Yup.object().shape({
    // mobileNumber: Yup.string()
    //   .matches(/^\d{10}$/, "Invalid mobile number")
    //   .required("Mobile No. is required"),
    // email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    investmentAmount: Yup.number()
      .typeError("Investment Amount must be a number")
      .positive("Investment Amount must be positive")
      .required("Investment Amount is required"),
    brokerId: Yup.string().required("Broker Name is required"),
    paymentModeId: Yup.string().required("Payment Mode is required"),
    mt5Id: Yup.string().notRequired(),
    mt5Password: Yup.string().notRequired(),
  });
  const storage = getStorage();
  // Define state variables for form data, errors, form validity, payment types, and broker names
  const [formData, setFormData] = useState({
    mobileNumber: "",
    email: "",
    password: "",
    investmentAmount: 0,
    brokerId: "",
    paymentModeId: "",
    md5Userid: "",
    mt5Password: "",
    tModeType: "",
    imageUrl: "",
  });
  const [errors, setErrors] = useState({});
  const [req, setReq] = useState([]);
  const [open, setOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // const [paymentTypes, setPaymentTypes] = useState([]);
  const [brokerNames, setBrokerNames] = useState([]);
  console.log("datda---->>", formData);

  const paymentTypes = [
    { paymentModeId: "1", paymentModeType: "Cash" },
    { paymentModeId: "2", paymentModeType: "TRC20" },
    // { paymentModeId: '3', paymentModeType: 'Card' },
  ];

  const types = [
    { typeModeId: "1", tModeType: "IB" },
    { typeModeId: "2", tModeType: "Investment" },
  ];
  const handleViewRequest = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        // Fetch the members collection from Firestore
        const querySnapshot = await getDocs(
          collection(db, "liveAccountRequests")
        );
        const liveAccountRequestsList = querySnapshot.docs
          .map((doc) => ({
            id: doc.id, // Firestore document ID
            ...doc.data(), // All other data in the document
          }))
          .filter((doc) => doc.createdBy === user.uid);

        console.log(
          "Fetched liveAccountRequests from Firebase:",
          liveAccountRequestsList
        );
        setReq(liveAccountRequestsList); // Set members in state
      } catch (error) {
        console.error(
          "Error fetching liveAccountRequests from Firebase:",
          error
        );
        toast.error("Error fetching liveAccountRequests from Firebase!");
      }
    };

    fetchRequest();
  }, []);
  // Fetch payment types
  // useEffect(() => {
  //   const fetchPaymentTypes = async () => {
  //     try {
  //       const response = await axios.get(
  //         "http://localhost:8082/v1/liveaccount/paymenttype"
  //       );
  //       setPaymentTypes(response.data);
  //     } catch (error) {
  //       console.error("Error fetching payment types:", error);
  //       toast.error("Error fetching payment types!");
  //     }
  //   };
  //   fetchPaymentTypes();
  // }, []);

  // // Fetch broker names
  // useEffect(() => {
  //   const fetchBrokerNames = async () => {
  //     try {
  //       const response = await axios.get(
  //         "http://localhost:8082/v1/liveaccount/brockername"
  //       );
  //       setBrokerNames(response.data);
  //     } catch (error) {
  //       console.error("Error fetching broker names:", error);
  //       toast.error("Error fetching broker names!");
  //     }
  //   };
  //   fetchBrokerNames();
  // }, []);

  // Handler for form submission
  // const handleSubmit = async (event) => {
  //   event.preventDefault(); // Prevent the form from submitting the traditional way

  //   try {
  //     // Validate form data
  //     await validationSchema.validate(formData, { abortEarly: false });

  //     // Prepare the data to be sent in the request
  //     const requestData = {
  //       liveRequestId: '',
  //       userId: user.userId,
  //       investmentAmount: Number(formData.investmentAmount),
  //       sdstarPassw: formData.password,
  //       md5Userid: formData.md5Userid,
  //       md5Passw: formData.password,
  //       paymentModeId: formData.paymentModeId,
  //       brokerId: formData.brokerId,
  //       createdAt: new Date().toISOString(),
  //       createdBy: user.userId,
  //       updatedAt: new Date().toISOString(),
  //       updatedBy: '',
  //       status: 'Pending',
  //     };
  //     console.log('requestData', requestData);
  //     // return;
  //     // Make the POST request using Axios
  //     const response = await axios.post(
  //       'http://localhost:8082/v1/liveaccount/request',
  //       requestData,
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     console.log('Response data:', response.data);

  //     // Show success notification
  //     toast.success('Form submitted successfully!');

  //     // Clear the form
  //     setFormData({
  //       mobileNumber: '',
  //       email: '',
  //       password: '',
  //       investmentAmount: '',
  //       brokerId: '',
  //       paymentModeId: '',
  //       md5Userid: '',
  //       mt5Password: '',
  //     });
  //     setErrors({});
  //   } catch (error) {
  //     if (error.name === 'ValidationError') {
  //       // Yup validation error
  //       const yupErrors = {};
  //       error.inner.forEach((e) => {
  //         yupErrors[e.path] = e.message;
  //       });
  //       setErrors(yupErrors);
  //     } else if (error.response) {
  //       // Server error
  //       console.error('Server error:', error.response.data);
  //       toast.error(`Error submitting form: ${error.response.data.message}`);
  //     } else {
  //       // Other errors
  //       console.error('Error submitting form:', error);
  //       toast.error('Error submitting form!');
  //     }
  //   }
  // };

  const handleInputChange = async (event) => {
    const { name, value, files } = event.target;

    if (name === "image") {
      // If an image file is selected, upload it to Firebase Storage
      const file = files[0];
      if (file) {
        try {
          const imageRef = ref(storage, `images/${file.name}`);
          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);
          setFormData((prevData) => ({ ...prevData, imageUrl: downloadURL })); // Update formData with the image URL
          toast.success("Image uploaded successfully!");
        } catch (error) {
          console.error("Error uploading image:", error);
          toast.error("Failed to upload image");
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the form from submitting the traditional way

    try {
      // Validate form data
      await validationSchema.validate(formData, { abortEarly: false });

      // Prepare the data to be saved to Firebase
      const requestData = {
        liveRequestId: "",
        investmentAmount: Number(formData.investmentAmount),
        md5Userid: formData.md5Userid,
        md5Passw: formData.password,
        tModeType: formData.tModeType,
        paymentModeId: formData.paymentModeId,
        brokerId: formData.brokerId,
        createdAt: new Date().toISOString(),
        createdBy: user.uid,
        updatedAt: new Date().toISOString(),
        updatedBy: "",
        status: "Pending",
        imageUrl: formData.imageUrl,
      };

      // Save data to Firestore
      await addDoc(collection(db, "liveAccountRequests"), requestData);

      // Show success notification
      toast.success("Form submitted successfully!");

      // Clear the form
      setFormData({
        mobileNumber: "",
        password: "",
        investmentAmount: "",
        brokerId: "",
        paymentModeId: "",
        md5Userid: "",
        mt5Password: "",
        tModeType: "",
        imageUrl: "",
      });
      setErrors({});
    } catch (error) {
      if (error.name === "ValidationError") {
        // Yup validation error
        const yupErrors = {};
        error.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setErrors(yupErrors);
      } else {
        // Handle other errors
        console.error("Error submitting form:", error);
        toast.error("Error submitting form!");
      }
    }
  };

  // Handler for form input change
  // const handleInputChange = async (event) => {
  //   const { name, value } = event.target;
  //   console.log(value);
  //   try {
  //     // Update the form data
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });

  //     // Validate the changed field
  //     await Yup.reach(validationSchema, name).validate(value);

  //     // Clear any previous errors for the field
  //     setErrors((prevErrors) => {
  //       return { ...prevErrors, [name]: '' };
  //     });
  //   } catch (error) {
  //     // Set error message for the field
  //     setErrors((prevErrors) => {
  //       return { ...prevErrors, [name]: error.message };
  //     });
  //   }
  // };

  // Effect to check form validity
  useEffect(() => {
    validationSchema.isValid(formData).then((valid) => {
      setIsValid(valid);
    });
  }, [formData, validationSchema]);

  return (
    <div style={{ backgroundColor: "#40474F", minHeight: "100vh" }}>
      <CssVarsProvider>
        <Box sx={{ padding: 2 }}>
          <Typography level="h3" style={{ color: "#FFFFFF" }} fontWeight="lg">
            LIVE ACCOUNT BALANCE REQUEST
          </Typography>
          <Typography level="body-sm" style={{ color: "#FFFFFF" }} my={2}>
            Live Account balance request from your account
          </Typography>
          <Sheet variant="outlined" sx={{ padding: 3, borderRadius: "sm" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", my: 2 }}>
              <Button
                type="submit"
                color="warning"
                variant="solid"
                sx={{ marginTop: 2 }}
                // onClick={handleViewRequest}
                onClick={handleViewRequest}
              >
                View Requests
              </Button>
            </Box>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
                {/* <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Mobile No.:</FormLabel>
                <Input
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  required
                />
                {errors.mobileNumber && (
                  <Typography color="error" variant="body2">
                    {errors.mobileNumber}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Email:</FormLabel>
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && (
                  <Typography color="error" variant="body2">
                    {errors.email}
                  </Typography>
                )}
              </FormControl> */}

                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Payment Mode:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="paymentModeId"
                    value={formData.paymentModeId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Payment Mode</option>
                    {paymentTypes.map((type) => (
                      <option
                        key={type.paymentModeId}
                        value={type.paymentModeType}
                      >
                        {type.paymentModeType}
                      </option>
                    ))}
                  </select>
                  {errors.paymentModeId && (
                    <Typography color="error" variant="body2">
                      {errors.paymentModeId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Select Image:</FormLabel>
                  <Input
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                  />
                  {errors.imageUrl && (
                    <Typography color="error">{errors.imageUrl}</Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Investment Amount (USDT):</FormLabel>
                  <Input
                    name="investmentAmount"
                    value={formData.investmentAmount}
                    onChange={handleInputChange}
                    required
                    type="number"
                  />
                  {errors.investmentAmount && (
                    <Typography color="error" variant="body2">
                      {errors.investmentAmount}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Broker Name:</FormLabel>
                  <input
                    type="text"
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="brokerId"
                    value={formData.brokerId}
                    onChange={handleInputChange}
                    placeholder="Enter Broker Name"
                    required
                  />
                  {errors.brokerId && (
                    <Typography color="error" variant="body2">
                      {errors.brokerId}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Password:</FormLabel>
                  <Input
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    type="password"
                  />
                  {errors.password && (
                    <Typography color="error" variant="body2">
                      {errors.password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Id. (optional):</FormLabel>
                  <Input
                    name="md5Userid"
                    value={formData.md5Userid}
                    onChange={handleInputChange}
                  />
                  {errors.mt5Id && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Id}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>MT5 Password. (optional):</FormLabel>
                  <Input
                    name="mt5Password"
                    value={formData.mt5Password}
                    onChange={handleInputChange}
                    type="password"
                  />
                  {errors.mt5Password && (
                    <Typography color="error" variant="body2">
                      {errors.mt5Password}
                    </Typography>
                  )}
                </FormControl>
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Type:</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600"
                    name="tModeType"
                    value={formData.tModeType}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Type</option>
                    {types.map((t) => (
                      <option key={t.typeModeId} value={t.tModeType}>
                        {t.tModeType}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  color="primary"
                  variant="solid"
                  sx={{ marginTop: 2 }}
                  disabled={!isValid} // Disable button if form is not valid
                >
                  Submit Request
                </Button>
              </Box>
            </form>
          </Sheet>
        </Box>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showModal}
          onClose={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Sheet
            variant="outlined"
            sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              sx={{ fontWeight: "lg", mb: 2 }}
            >
              Account Request Details
            </Typography>
            {Array.isArray(req) && req.length > 0 ? (
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Broker ID
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Investment Amount
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Payment Mode
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Status
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Image
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {req.map((request, index) => (
                    <tr key={index}>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {request.brokerId}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {request.investmentAmount}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {request.paymentModeId}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {request.status}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        <img
                          src={request.imageUrl}
                          alt="req"
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "4px",
                          }} // Adjust size and styling as needed
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No account requests available.</p>
            )}
          </Sheet>
        </Modal>
        <SupportSystem />
      </CssVarsProvider>
    </div>
  );
};

export default LiveBalanceRequest;
