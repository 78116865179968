import { Box, Sheet } from "@mui/joy";
import React, { useState } from "react";
// import background from "../../assets/background.webp";

const LevelView = () => {
  // Define the initial data for the levels
  const initialLevelsData = Array.from({ length: 32 }, (_, index) => ({
    level: `Level - ${index + 1}`,
    totalMember: 0,
    activeMember: 0,
    inactiveMember: 0,
    business: "0.00",
  }));

  const [levelsData, setLevelsData] = useState(initialLevelsData);

  // Handle the view button click
  const handleViewClick = (level) => {
    console.log(`Viewing details for ${level.level}`);
  };

  return (
    <Box
      sx={{
        padding: 2,
        minHeight: "100vh",
        // backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      bgcolor="neutral.softHoverBg"
    >
      <h2 className="text-xl font-bold mb-4">Level View</h2>
      <Sheet variant="outlined" sx={{ padding: 3, borderRadius: "sm" }}>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Level</th>
              <th className="px-4 py-2">Total Member</th>
              <th className="px-4 py-2">Active Member</th>
              <th className="px-4 py-2">Inactive Member</th>
              <th className="px-4 py-2">Business</th>
              <th className="px-4 py-2">View</th>
            </tr>
          </thead>
          <tbody>
            {levelsData.map((level) => (
              <tr key={level.level}>
                <td className="px-4 py-2">{level.level}</td>
                <td className="px-4 py-2">{level.totalMember}</td>
                <td className="px-4 py-2">{level.activeMember}</td>
                <td className="px-4 py-2">{level.inactiveMember}</td>
                <td className="px-4 py-2">{level.business}</td>
                <td className="px-4 py-2">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleViewClick(level)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Sheet>
    </Box>
  );
};

export default LevelView;
