import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../theme';
import { db } from '../firebase'; // Import Firebase setup
import SupportSystem from './support/SupportSystem';
import { addDoc, collection } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function OldSettleMent() {
  // State to handle form inputs
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: '',
    password: '',
    amount: '',
    balance: '',
    percentage: '',
    approvedamount: '',
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add form data to Firestore under the "oldSettlement" collection
      await addDoc(collection(db, 'oldSettlement'), {
        ...formData,
        timestamp: new Date(),
      });
      toast.success('Data saved successfully!');
      setFormData({
        id: '',
        password: '',
        amount: '',
        balance: '',
        percentage: '',
        approvedamount: '',
      });
      navigate('/oldsettlementview');
    } catch (error) {
      console.error('Error saving data: ', error);
      alert('Error saving data.');
    }
  };

  return (
    <div style={{ backgroundColor: '#40474F', minHeight: '100vh' }}>
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box
          sx={{
            padding: 2,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundColor: '#40474F',
            width: '100%',
          }}
        >
          <Typography
            level="h2"
            style={{ color: '#FFFFFF' }}
            fontWeight="lg"
            sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, textAlign: 'center' }}
          >
            Old Settlements
          </Typography>
          <Sheet
            variant="soft"
            sx={{
              padding: { xs: 2, sm: 3 },
              borderRadius: 'sm',
              width: '100%',
              marginTop: 2,
              marginX: 'auto',
              bgcolor: 'neutral.softHoverBg',
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  flexWrap: 'wrap',
                  gap: 2,
                  width: '100%',
                }}
              >
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>ID</FormLabel>
                  <Input
                    placeholder="Enter your ID"
                    type="text"
                    name="id"
                    value={formData.id}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Password</FormLabel>
                  <Input
                    placeholder="Enter your Password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Amount</FormLabel>
                  <Input
                    placeholder="Enter Amount"
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Balance</FormLabel>
                  <Input
                    placeholder="Enter Balance"
                    type="number"
                    name="balance"
                    value={formData.balance}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Approved Amount</FormLabel>
                  <Input
                    placeholder="Enter Approved Amount"
                    type="number"
                    name="approvedamount"
                    value={formData.approvedamount}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl
                  sx={{
                    flex: 1,
                    minWidth: { xs: '100%', sm: 'calc(50% - 8px)' },
                  }}
                >
                  <FormLabel>Percentage</FormLabel>
                  <Input
                    placeholder="Enter Percentage"
                    type="number"
                    name="percentage"
                    value={formData.percentage}
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="solid"
                  color="danger"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                  onClick={() => navigate('/oldsettlementview')}
                >
                  Close
                </Button>
                <Button
                  type="submit"
                  variant="solid"
                  color="success"
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Sheet>
          <SupportSystem />
        </Box>
      </CssVarsProvider>
    </div>
  );
}

export default OldSettleMent;
