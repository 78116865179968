import * as React from "react";
import Box from "@mui/joy/Box";
import { Link } from "@mui/material";
import { Button, Typography, useColorScheme } from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { useNavigate } from "react-router-dom";
import { RiMenuFill } from "react-icons/ri";

import logo from "../assets/icons/BLogo.png";
import logo_name from "../assets/Bhead.png";

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  // return (
  //   <IconButton
  //     id="toggle-mode"
  //     size="lg"
  //     variant="soft"
  //     color="neutral"
  //     onClick={() => {
  //       if (mode === "light") {
  //         setMode("dark");
  //       } else {
  //         setMode("light");
  //       }
  //     }}
  //     sx={{
  //       zIndex: 999,
  //       boxShadow: "sm",
  //     }}
  //   >
  //     {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />} 
  //   </IconButton>
  // );
}

export default function AppNav() {
  const [showDropDown, setShowDropDown] = React.useState(false);
  const navigate = useNavigate();

  return (
    <div
     className="w-full shadow  text-white"
     style={{ backgroundColor: "#F13F41" }}
     >
      <div className="flex justify-between p-4 items-center flex-row">
        <div className="flex items-center">
          <div
            className="me-4 md:hidden"
            onClick={() => setShowDropDown(!showDropDown)}
          >
            <RiMenuFill />
          </div>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <img className="w-12 me-2" src={logo} />
            {/* <h1 style={{fontSize:34 }}> BRIARWOOD VENTURES </h1> */}
            <img className="h-10 me-2" src={logo_name} />
            {/* <Typography variant="h6">ForexPro</Typography> */}
          </Box>
        </div>
        <div className="md:flex hidden items-center text-lg">
          <Button
            sx={{ mx: 2 , fontSize: 20, fontWeight: 250 }}
            color="inherit"
            underline="none"
            onClick={() => navigate("/")}
          >
            Home
          </Button>
          <Button
            sx={{ mx: 2 , fontSize: 20, fontWeight: 250 }}
            color="inherit"
            underline="none"
            onClick={() => navigate("/about")}
          >
            About Us
          </Button>
          <Button
            sx={{ mx: 2 , fontSize: 20, fontWeight: 250 }}
            color="inherit"
            underline="none"
            onClick={() => navigate("/calls")}
          >
            Our Call
          </Button>
          <Button
            sx={{ mx: 2  , fontSize: 20, fontWeight: 250}}
            color="inherit"
            underline="none"
            onClick={() => navigate("/education")}
          >
            Education
          </Button>
          {/* <Button
            sx={{ mx: 2  , fontSize: 20, fontWeight: 250}}
            color="inherit"
            underline="none"
            onClick={() => navigate("/pricing")}
          >
            Pricing
          </Button> */}
          <Button
            sx={{ mx: 2  , fontSize: 20, fontWeight: 250}}
            color="inherit"
            underline="none"
            // onClick={() => navigate("/pricing")}
          >
            Product
          </Button>
          <Button
            sx={{ mx: 2  , fontSize: 20, fontWeight: 250}}
            color="inherit"
            underline="none"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </Button>
          <Button
            onClick={() => navigate("/login")}
            color="success"
            size="lg"
            variant="soft"
            style={{ marginRight: '10px' }}
          >
            Login
          </Button>
          {/* <Button
                color="success"
                size="lg"
                variant="soft"
                onClick={() => navigate("/signup")}
              >
                Signup
              </Button> */}
          {/* <div className="flex items-center mx-2">
          <ColorSchemeToggle />
        </div> */}
        </div>
      </div>
      {showDropDown && (
        <div className="md:hidden">
          <div className="w-36">
            <div className="flex flex-col p-4">
              <div className="py-2 shadow">
                <Link sx={{ mx: 2 }} color="inherit" underline="none" href="/">
                  Home
                </Link>
              </div>
              <div className="py-2 shadow">
                <Link
                  sx={{ mx: 2 }}
                  color="inherit"
                  underline="none"
                  href="/album"
                >
                  Album
                </Link>
              </div>
              <div className="py-2 shadow">
                <Link sx={{ mx: 2 }} color="inherit" underline="none" href="#">
                  Pricing
                </Link>
              </div>
            <div className="flex flex-row justify-between items-center mx-2 ">

              <Button
                color="success"
                size="lg"
                variant="soft"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              {/* <Button
                color="success"
                size="lg"
                variant="soft"
                onClick={() => navigate("/signup")}
              >
                Signup
              </Button> */}
              
              {/* <div className="flex items-center mx-2">
          <ColorSchemeToggle />
        </div> */}
            </div>
            <div className="flex flex-row justify-between items-center mx-2 ">
              
            </div>
        </div>
          </div>
        </div>
      )}
    </div>
  );
}
