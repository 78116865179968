import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Sheet,
  Button,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import SendIcon from '@mui/icons-material/Send';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase'; // Import your Firestore database
import framesxTheme from '../../theme';
import SupportSystem from '../support/SupportSystem';
import { toast } from 'react-toastify';

const Message = () => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  const handleSend = async () => {
    try {
      await addDoc(collection(db, 'messages'), {
        title,
        message,
        createdAt: Timestamp.now(), // Firebase timestamp for current date and time
      });
      toast.success("Message sent successfully");
      setTitle('');
      setMessage('');
    } catch (e) {
      console.error("Error adding document: ", e);
      alert('Failed to send message');
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: '100vh',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#40474F',
        }}
        bgcolor="neutral.softHoverBg"
      >
        <Typography level="h2" style={{ color: '#FFFFFF' }} fontWeight="lg">
          Message
        </Typography>
        <Typography level="body-sm" style={{ color: '#FFFFFF' }} mb={2}>
          Send Your Message
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: 'sm',
            maxWidth: 1500,
            marginTop: 2,
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>Title</FormLabel>
              <Input
                placeholder="Enter New Password"
                // value={newPassword}
                // onChange={(e) => setNewPassword(e.target.value)}

                type="textbox"
              />
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: 'black' }}>Message</FormLabel>
              <Input
                placeholder="Enter Confirm Password"
                // value={confirmPassword}
                // onChange={(e) => setConfirmPassword(e.target.value)}

                type="textarea"
              />
            </FormControl>
            <Button
              variant="solid"
              color="primary"
              sx={{
                alignSelf: 'center',
                marginTop: 2,
                display: 'flex',
                gap: '4px',
              }}
              onClick={handleSend}
            >
              Send
              <SendIcon />
            </Button>
          </Box>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
};

export default Message;
