import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  Select,
  MenuItem,
} from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import * as Yup from "yup";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import { db } from "../../firebase";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";
// import background from "../../assets/background.webp";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import SupportSystem from "../support/SupportSystem";

// Generate a 6-digit OTP
const generateOTP = () =>
  Math.floor(100000 + Math.random() * 900000).toString();

const MyProfile = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
  });

  const [user] = useState(JSON.parse(localStorage.getItem("user")));
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [countries, setCountries] = useState([]);
  const [idCards, setIdCards] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    country: "",
    state: "",
    nomineeName: "",
    nomineeRelation: "",
    fatherName: "",
    dateOfBirth: "",
    gender: "",
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [prevEmail, setPrevEmail] = useState("");
  const [prevMobile, setPrevMobile] = useState("");
  const [selectedModalOption, setSelectedModalOption] = useState("");
  console.log("formData", formData, userId);
  console.log("sne", userId);
  const tenantsCollection = collection(db, "members");

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setFormData(data[0]);

        console.log("111----------------->", data[0].id);
        setUserId(data[0].id);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where("email", "==", user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }
  // function MembersComponent({ id }) {
  //   const [memberData, setMemberData] = useState(null);

  // const fetchIdCards = async (country) => {
  //   const response = await axios.get(
  //     `http://ec2-13-201-97-103.ap-south-1.compute.amazonaws.com:8082/v1/user/idcard?country=${country}`
  //   );
  //   return response.data;
  // };
  const fetchCountries = async () => {
    try {
      // const response = await axios.get(
      //   "http://ec2-13-201-97-103.ap-south-1.compute.amazonaws.com:8082/v1/user/country"
      // );
      const response = await axios.get(
        "https://restcountries.com/v3.1/all?fields=name,flags"
      );
      setCountries(response.data);
      // if (response.data.length > 0) {
      //   const cardsData = await fetchIdCards(response.data[0]?.countryName);
      //   setIdCards(cardsData);
      //   setFormData({ ...formData, country: "INDIA" });
      // }
    } catch (error) {
      console.error("Error fetching countries:", error);
      toast.error("Error fetching countries!");
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const handleResponseDialogClose = () => {
    setResponseDialogOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (userId) {
        const userDocRef = doc(db, "members", userId);

        // Update the document with formData values
        await updateDoc(userDocRef, {
          name: formData.name,
          mobileNumber: formData.mobileNumber,
          email: formData.email,
          country: formData.country,
          state: formData.state,
          nomineeName: formData.nomineeName,
          nomineeRelation: formData.nomineeRelation,
          fatherName: formData.fatherName,
          dateOfBirth: formData.dateOfBirth,
          gender: formData.gender,
        });

        toast.success("Profile updated successfully!");

        setIsEditing(false); // Exit editing mode
      } else {
        toast.error("User ID not found. Unable to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // if (name === "country") {
    //   const cardsData = await fetchIdCards(value);
    //   setIdCards(cardsData);
    // }

    try {
      await Yup.reach(validationSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (validationError) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationError.message,
      }));
    }
  };

  const handleIdCardChange = (event, idCardId) => {
    const { value } = event.target;
    setIdCards((prevIdCards) =>
      prevIdCards.map((idCard) =>
        idCard.idCardId === idCardId
          ? { ...idCard, idCardNumber: value }
          : idCard
      )
    );
  };

  useEffect(() => {
    validationSchema.isValid(formData).then((valid) => {
      setIsValid(valid);
    });
  }, [formData, idCards]);

  const handleSlotChange = (slot) => {
    // Handle slot change logic here
    console.log("Selected slot:", slot);
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box sx={{ padding: 2, backgroundColor: "#40474F", minHeight: "100vh" }}>
        <div className="px-4">
          <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
            My Profile
          </Typography>
        </div>
        <Sheet
          variant="soft"
          sx={{ padding: 3, borderRadius: "sm", maxWidth: 1500, marginTop: 2 }}
          bgcolor="neutral.softHoverBg"
        >
          <form onSubmit={handleSubmit}>
            <Typography level="h4" color="text.primary" mb={2}>
              Personal Details
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Name:</FormLabel>
                <Input
                name="name"
                value={formData?.name}
                onChange={(e) => {
                  // Update the value with uppercase letters
                  handleInputChange({
                    target: {
                      name: "name",
                      value: e.target.value.toUpperCase(),
                    },
                  });
                }}
                required
                sx={{ textTransform: "uppercase" }}
              />
                {errors.name && (
                  <Typography color="error" variant="body2">
                    {errors.name}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Mobile Number:</FormLabel>
                <Input
                  name="mobileNumber"
                  value={formData?.mobileNumber}
                  onChange={handleInputChange}
                  required
                />
                {errors.mobileNumber && (
                  <Typography color="error" variant="body2">
                    {errors.mobileNumber}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Email ID :</FormLabel>
                <Input
                  name="email"
                  value={formData?.email}
                  onChange={handleInputChange}
                  required
                />
                {errors.email && (
                  <Typography color="error" variant="body2">
                    {errors.email}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Father/Guardian name :</FormLabel>
                <Input
                  name="fatherName"
                  value={formData?.fatherName}
                  onChange={(e) => {
                    // Convert the input value to uppercase before updating state
                    handleInputChange({
                      target: {
                        name: "fatherName",
                        value: e.target.value.toUpperCase(),
                      },
                    });
                  }}
                  required
                  sx={{ textTransform: "uppercase" }} // Optional, for visual styling
                />
                {errors.name && (
                  <Typography color="error" variant="body2">
                    {errors.name}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Date of Birth :</FormLabel>
                <Input
                  type="date"
                  name="dateOfBirth"
                  value={formData?.dateOfBirth}
                  onChange={handleInputChange}
                  required
                />
                {errors.name && (
                  <Typography color="error" variant="body2">
                    {errors.name}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Gender :</FormLabel>

                <select
                  id="timeSlot"
                  className="px-2 py-2 border rounded bg-transparent border-gray-600"
                  name="gender"
                  value={formData?.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Select Your Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Country :</FormLabel>
                <select
                  className="px-2 py-2 border rounded bg-transparent border-gray-600"
                  name="country"
                  value={formData?.country}
                  onChange={handleInputChange}
                  required
                >
                  {countries &&
                    Object.keys(countries).map((key) => (
                      <option key={key} value={countries[key].name.common}>
                        <img
                          src={countries[key].flags.png}
                          alt={`Flag of ${countries[key].name.common}`}
                          style={{ width: "20px", marginRight: "10px" }}
                        />
                        {countries[key].name.common}
                      </option>
                    ))}
                </select>
                {errors.country && (
                  <Typography color="error" variant="body2">
                    {errors.country}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>State:</FormLabel>
                <Input
                  name="state"
                  value={formData?.state}
                  onChange={(e) => {
                    // Convert the input value to uppercase before updating the state
                    handleInputChange({
                      target: {
                        name: "state",
                        value: e.target.value.toUpperCase(),
                      },
                    });
                  }}
                  required
                  sx={{ textTransform: "uppercase" }} // Optional, for visual styling
                />
                {errors.state && (
                  <Typography color="error" variant="body2">
                    {errors.state}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Nominee Name :</FormLabel>
                <Input
                  name="nomineeName"
                  value={formData?.nomineeName}
                  onChange={(e) => {
                    // Convert the input value to uppercase before updating the state
                    handleInputChange({
                      target: {
                        name: "nomineeName",
                        value: e.target.value.toUpperCase(),
                      },
                    });
                  }}
                  required
                  sx={{ textTransform: "uppercase" }} // Optional, for visual styling
                />
                {errors.name && (
                  <Typography color="error" variant="body2">
                    {errors.name}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Nominee Relation :</FormLabel>
                <Input
                  name="nomineeRelation"
                  value={formData?.nomineeRelation}
                  onChange={(e) => {
                    // Convert the input value to uppercase before updating the state
                    handleInputChange({
                      target: {
                        name: "nomineeRelation",
                        value: e.target.value.toUpperCase(),
                      },
                    });
                  }}
                  required
                  sx={{ textTransform: "uppercase" }} // Optional, for visual styling
                />
                {errors.name && (
                  <Typography color="error" variant="body2">
                    {/* {errors.name} */}
                  </Typography>
                )}
              </FormControl>
              {idCards.map((idCard) => (
                <FormControl key={idCard.idCardId} sx={{ marginBottom: 2 }}>
                  <FormLabel>{idCard.idCardName}:</FormLabel>
                  <Input
                    name={idCard.idCardName.toLowerCase()}
                    value={idCard.idCardNumber || ""}
                    onChange={(event) =>
                      handleIdCardChange(event, idCard.idCardId)
                    }
                    required
                  />
                </FormControl>
              ))}
            </div>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                color="primary"
                variant="solid"
                sx={{
                  marginTop: 2,
                  transition: "0.3s",
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    color: "white",
                    transform: "scale(1.05)",
                  },
                }}
                disabled={!isValid}
              >
                Save
              </Button>
            </Box>
          </form>
        </Sheet>
      </Box>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={responseDialogOpen}
        onClose={handleResponseDialogClose}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 700,
            borderRadius: "md",
            p: 5,
            boxShadow: "lg",
          }}
        >
          <ModalClose variant="plain" sx={{ m: 0 }} />
          <Typography
            level="body-lg"
            fontWeight="md"
            color="text.secondary"
            mb={2}
          >
            Save the User ID and Password of New User
          </Typography>
          <Typography>
            <b>User ID : </b> {prevEmail}
          </Typography>
          <Typography>
            <b>Password : </b> {password}
          </Typography>
          <Button
            variant="solid"
            color="primary"
            sx={{ alignSelf: "center", marginTop: 2 }}
          >
            Save
          </Button>
        </Sheet>
      </Modal>
      <SupportSystem />
    </CssVarsProvider>
  );
};

export default MyProfile;
