// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpiRltBRAsPCmY_HwFgw1bJ__KlWCr8mw",
  authDomain: "samurifx.firebaseapp.com",
  projectId: "samurifx",
  storageBucket: "samurifx.appspot.com",
  messagingSenderId: "619532992729",
  appId: "1:619532992729:web:2a8430dd6457f0e81cbec8",
  measurementId: "G-0B48J6E3W5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
// const storage = getStorage(app)
const db = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
});

export {
  auth,
  db,
  // storage,
  // rdb
};
