// // Home.js
// import React, { useState } from "react";
// import { Box } from "@mui/joy";
// import axios from "axios";
// import { toast } from "react-toastify";
// import Typography from "@mui/joy/Typography";

// // import background from "../assets/background.webp"
// // import SupportSystem from "../support/SupportSystem";
// import SupportSystem from './support/SupportSystem';
// import { useAuth } from "../context/AuthContext";

// const Home = () => {
//   const {user} = useAuth()
//   const userId = user.uid;

//   const referralLink = `https://briarv.com/signup?ref=${userId}`;
//   console.log('snehj', user , referralLink );

//   // const [formData, setFormData] = useState({
//   //   name: "",
//   //   mobileNumber: "",
//   //   emailid: "",
//   //   country: "",
//   //   state: "",
//   //   pan: "",
//   // });

//   // const handleSubmit = async (event) => {
//   //   event.preventDefault();

//   //   try {
//   //     const response = await axios.post(
//   //       "http://localhost:8080/v1/user/save",
//   //       formData,
//   //       {
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //           Accept: "*/*",
//   //         },
//   //         mode: "cors",
//   //       }
//   //     );

//   //     console.log("Data posted successfully:", response.data);

//   //     toast.success("Data posted successfully!");

//   //     setFormData({
//   //       name: "",
//   //       mobileNumber: "",
//   //       emailid: "",
//   //       country: "",
//   //       state: "",
//   //       pan: "",
//   //     });
//   //   } catch (error) {
//   //     console.error("Error posting data:", error);
//   //   }
//   // };

//   // const handleInputChange = (event) => {
//   //   const { name, value } = event.target;
//   //   setFormData({
//   //     ...formData,
//   //     [name]: value,
//   //   });
//   // };

//   return (
//     <>
//       <Box
//         sx={{
//           width: "100%",
//           height: "100vh",
//           // marginTop: "-25px",
//           bgcolor: "background.body",
//           padding: "15px",
//           bgcolor: "#40474F",
//           // backgroundImage: `url(${background})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundRepeat: "no-repeat",
//         }}
//       >
//         <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
//             Welcome to the Briarwood Ventures !
//           </Typography>
//         <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
//             Referra lLink = {referralLink}
//           </Typography>
//       </Box>
//       <SupportSystem />
//     </>
//   );
// };

// export default Home;

import React, { useEffect, useState } from "react";
import { Box } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import QRCode from "react-qr-code"; // Import QRCode component
import { useAuth } from "../context/AuthContext";
import SupportSystem from "./support/SupportSystem";

const Home = () => {
  const { user } = useAuth();
  const [isVerified, setIsVerified] = useState(user.emailVerified);

  const userId = user?.uid;
  const referralLink = `https://briarv.com/signup?ref=${userId}`;

  useEffect(() => {
    const checkEmailVerification = async () => {
      if (!isVerified) {
        await user.reload(); // Reload the user to get the latest email verification status
        setIsVerified(user.emailVerified);
      }
    };

    // Check the email verification status every 5 seconds (adjust as needed)
    const intervalId = setInterval(checkEmailVerification, 5000);
    return () => clearInterval(intervalId); // Clear the interval on component unmount
  }, [isVerified, user]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          bgcolor: "background.body",
          padding: "15px",
          bgcolor: "#40474F",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {!isVerified ? (
          <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
            Please verify your email to access Briarwood Ventures!
          </Typography>
        ) : (
          <>
            <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
              Welcome to the Briarwood Ventures!
            </Typography>
          </>
        )}
      </Box>
      <SupportSystem />
    </>
  );
};

export default Home;
