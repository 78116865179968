import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Sheet,
  Input,
  Button,
} from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import SupportSystem from "../support/SupportSystem";

function TrcWithdrawal() {
  const [selectedModalOption, setSelectedModalOption] = useState("");

  const handleSlotChange = (slot) => {
    console.log("Selected slot:", slot);
  };
  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#40474F",
        }}
      >
        <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
          TRC20 Withdrawal
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: "sm",
            maxWidth: 1500,
            marginTop: 2,
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Typography
            level="h3"
            style={{ color: "black", marginBottom: "16px" }}
            fontWeight="lg"
          >
            Poloniex supports withdrawing Tether USDT to the ETH/ERC-20, BSC,
            and Tron Network.
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControl sx={{ marginBottom: 2 }}>
              <FormLabel>Network :</FormLabel>
              <select
                id="timeSlot"
                className="px-2 py-2 border rounded bg-transparent border-gray-600"
                value={selectedModalOption}
                onChange={(e) => {
                  const slot = e.target.value;
                  handleSlotChange(slot);
                  setSelectedModalOption(slot);
                }}
              >
                <option value="" disabled>
                  Select Your Network
                </option>
                <option value="TRC20">TRC20</option>
                <option value="BEP20">BEP20(BSC)</option>
                <option value="ERC20">ERC20</option>
              </select>
            </FormControl>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel style={{ color: "black" }}>Address :</FormLabel>
              <Input placeholder="Enter Your Address" />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                padding: 2,
              }}
            >
              <FormControl sx={{ flex: 1, minWidth: "200px" }}>
                <FormLabel style={{ color: "black" }}>
                  Available Balance :
                </FormLabel>
                <Input placeholder="$34.57 USD" />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: "200px" }}>
                <FormLabel style={{ color: "black" }}>
                  Amount to Withdraw :
                </FormLabel>
                <Input placeholder="$34.57 USD" />
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                padding: 2,
              }}
            >
              <FormControl sx={{ flex: 1, minWidth: "200px" }}>
                <FormLabel style={{ color: "black" }}>
                  Transaction Fee :
                </FormLabel>
                <Input placeholder="$000.57 USD" />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: "200px" }}>
                <FormLabel style={{ color: "black" }}>Total</FormLabel>
                <Input placeholder="$0000.57 USD" />
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 2,
            }}
          >
            <Button variant="solid" color="success">
              Continue
            </Button>
          </Box>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default TrcWithdrawal;
