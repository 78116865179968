import React from "react";
import front1 from "../assets/icons/frontImg1.png";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import ArrowForward from "@mui/icons-material/ArrowForward";

function About() {
  return (
    <div style={styles.siteSection}>
      <div style={styles.container}>
        <div style={styles.row}>
          {/* Left Column (Image) */}
          <div style={styles.imageContainer}>
            <img src={front1} alt="Our Story" style={styles.aboutImage} />
          </div>

          {/* Right Column (Text) */}
          <div style={styles.textSection}>
            <Typography
              level="h1"
              fontWeight="xl"
              fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
              textColor="black"
              style={{ marginBottom: '20px' }}
            >
              What is Trading ?
            </Typography>
            <Typography fontSize="0.875rem" lineHeight="lg" textColor="black">
              Trading involves the buying and selling of financial assets like
              stocks, currencies, and commodities in various markets. Traders
              aim to profit from price fluctuations, utilizing strategies such
              as technical analysis, fundamental analysis, and risk management.
            </Typography>
            <Button
              size="lg"
              endDecorator={<ArrowForward fontSize="xl" />}
              sx={{
                backgroundColor: "#F13F41",
                color: "white",
                "&:hover": {
                  backgroundColor: "#D63739",
                },
              }}
              style={{ marginTop: '20px' }}
            >
              Enquire Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  siteSection: {
    padding: "40px 0",
    backgroundColor: "#f9f9f9", // Light background color for contrast
  },
  container: {
    maxWidth: "1140px",
    margin: "0 auto",
    padding: "0 15px", // Added padding for smaller screens
  },
  row: {
    display: "flex",
    flexDirection: "row", // Default to row
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  imageContainer: {
    flex: "1 1 50%", // For larger screens
    padding: "15px",
  },
  textSection: {
    flex: "1 1 50%", // For larger screens
    paddingLeft: "20px",
  },
  aboutImage: {
    width: "100%",
    maxWidth: "400px", // Limits image width on larger screens
    height: "auto",
    borderRadius: "8px",
  },
  backdrop: {
    fontSize: "1.2rem",
    color: "#888",
    display: "block",
  },
  subtitle: {
    fontSize: "1.5rem",
    color: "#333",
    display: "block",
    marginTop: "10px",
  },
};

// Media Queries for responsiveness
const mediaQueries = {
  "@media (max-width: 768px)": {
    row: {
      flexDirection: "column", // Stack columns vertically on mobile
    },
    imageContainer: {
      flex: "1 1 100%", // Full width for image on mobile
      textAlign: "center", // Center the image
      marginBottom: "20px", // Add space between image and text
    },
    textSection: {
      flex: "1 1 100%", // Full width for text on mobile
      paddingLeft: "0", // Remove extra padding
      textAlign: "center", // Center the text
    },
    aboutImage: {
      maxWidth: "90%", // Make the image responsive on mobile
      margin: "0 auto", // Center the image
    },
  },
};

export default About;
