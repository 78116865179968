import React, { useState } from "react";
import { Box, Typography, Button, Modal, Sheet, IconButton } from "@mui/joy";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import MessageIcon from "@mui/icons-material/Message";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

function SupportSystem() {
  const [messages, setMessages] = useState([
    { sender: "bot", text: "Hello traders! How can I assist you today?" },
  ]);
  const [userInput, setUserInput] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSendMessage = () => {
    if (userInput.trim() !== "") {
      const newMessages = [...messages, { sender: "user", text: userInput }];
      const botResponse = getBotResponse(userInput);
      setMessages([...newMessages, { sender: "bot", text: botResponse }]);
      setUserInput("");
    }
  };

  const getBotResponse = (input) => {
    if (input.toLowerCase().includes("hello"))
      return "Hi there! How can I help you?";
    if (input.toLowerCase().includes("help"))
      return "Sure! I am here to help. What do you need assistance with?";
    if (input.toLowerCase().includes("how are you"))
      return "I am fine and you?";
    return "I'm here to help! Could you provide more details?";
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Button
        onClick={() => setIsPopupOpen(true)}
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          background: "#F13F41",
          color: "#fff",
          padding: "12px",
          borderRadius: "50%",
          fontWeight: "bold",
          minWidth: "50px",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)",
        }}
      >
        <MessageIcon fontSize="large" />
      </Button>

      <Modal
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        sx={{
          display: "flex",
          alignItems: "flex-end", // Align to the bottom of the screen
          justifyContent: "flex-end", // Align to the right of the screen
          position: "fixed", // Fix the position of the Modal to bottom-right
          bottom: 0,
          right: 0,
          margin: 0, // Remove any default margin
        }}
      >
        <Sheet
          sx={{
            width: "100%",
            maxWidth: 300,
            borderRadius: "16px",
            padding: 3,
            background: "linear-gradient(135deg, #40474F, #F13F41)",
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.3)",
            border: "1px solid #fff",
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          <IconButton
            onClick={() => setIsPopupOpen(false)}
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              color: "#fff",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            level="h2"
            fontSize={{ xs: "24px", md: "28px" }}
            textAlign="center"
            fontWeight="bold"
            mb={2}
            sx={{ color: "#fff" }}
          >
            Support Chat
          </Typography>
          <Box
            sx={{
              height: "300px",
              overflowY: "auto",
              borderRadius: "8px",
              padding: 2,
              mb: 2,
              background: "linear-gradient(135deg, #fff, #333)",
              boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.15)",
              border: "0.5px solid black",
            }}
          >
            {messages.map((message, index) => (
              <Box
                key={index}
                sx={{
                  textAlign: message.sender === "user" ? "right" : "left",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "inline-block",
                    padding: "10px",
                    borderRadius: "10px",
                    background:
                      message.sender === "user"
                        ? "linear-gradient(135deg, #F13F41, #FF7043)"
                        : "linear-gradient(135deg, #616A72, #40474F)",
                    color: "#fff",
                    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
                    maxWidth: "75%",
                    wordWrap: "break-word",
                    fontSize: "14px",
                  }}
                >
                  {message.text}
                </Box>
              </Box>
            ))}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <input
              type="text"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
              placeholder="Type your message..."
              style={{
                flex: 1,
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #F13F41",
                outline: "none",
                fontSize: "14px",
                color: "#333",
              }}
            />
            <button
              onClick={handleSendMessage}
              style={{
                padding: "13px 4px",
                borderRadius: "8px",
                background: "linear-gradient(135deg, #F13F41, #FF7043)",
                color: "#fff",
                border: "none",
                fontWeight: "bold",
                cursor: "pointer",
                fontSize: "14px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SendIcon style={{ color: "white" }} />
            </button>
          </Box>
        </Sheet>
      </Modal>
    </CssVarsProvider>
  );
}

export default SupportSystem;
