import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Sheet,
  Card,
  CardContent,
  Button,
  Modal,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import SupportSystem from '../support/SupportSystem';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { db } from '../../firebase';
import QRCode from 'react-qr-code'; // Import QRCode component
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { updateDoc, doc } from 'firebase/firestore';

function Dashboard() {
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const { user } = useAuth();
  const userId = user.uid;
  const [messages, setMessages] = useState([]);
  const [unseenMessagesCount, setUnseenMessagesCount] = useState(0);
  console.log('messages', messages);
  // useEffect(() => {
  //   const fetchMessages = async () => {
  //     const messagesRef = collection(db, 'messages');
  //     const q = query(messagesRef, orderBy('createdAt', 'desc'));
  //     const querySnapshot = await getDocs(q);
  //     const fetchedMessages = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setMessages(fetchedMessages);
  //   };

  //   fetchMessages();
  // }, []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesRef = collection(db, 'messages');
        const q = query(messagesRef, orderBy('createdAt', 'desc'));
        const querySnapshot = await getDocs(q);

        const fetchedMessages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(fetchedMessages);

        // Calculate unseen messages count
        const unseenCount = fetchedMessages.filter((msg) => !msg.seen).length;
        setUnseenMessagesCount(unseenCount);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [db]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    markMessagesAsSeen(); // Optionally mark all messages as seen
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const markMessagesAsSeen = async () => {
    const unseenMessages = messages.filter((msg) => !msg.seen);
    try {
      for (const message of unseenMessages) {
        const messageRef = doc(db, 'messages', message.id);
        await updateDoc(messageRef, { seen: true });
      }
      setUnseenMessagesCount(0); // Reset unseen count after marking as seen
    } catch (error) {
      console.error('Error updating message status:', error);
    }
  };
  const referralLink = `https://briarv.com/signup?ref=${userId}`;
  const [myClients, setMyClients] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const investmentSnapshot = await getDocs(
        collection(db, 'liveAccountRequests')
      );
      let totalInvestment = 0;
      investmentSnapshot.forEach((doc) => {
        const data = doc.data();
        totalInvestment += data.investmentAmount || 0;
      });
      setInvestmentAmount(totalInvestment);

      const membersSnapshot = await getDocs(collection(db, 'members'));
      setTotalClients(membersSnapshot.size);
    };

    fetchData();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    toast.success('Copied to clipboard');
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: '100vh',
          backgroundColor: '#40474F',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography level="h2" style={{ color: '#FFFFFF' }} fontWeight="lg">
            Dashboard
          </Typography>
          <div onClick={handleOpenModal} style={{marginRight:"7%"}}>
            <NotificationsActiveIcon sx={{ color: 'red', cursor: 'pointer' }} />
            {unseenMessagesCount > 0 && (
              <span
                style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}
              >
                {unseenMessagesCount} new
              </span>
            )}
          </div>
        </div>
        <Sheet
          variant="soft"
          sx={{
            padding: 3,
            borderRadius: 'sm',
            maxWidth: 1500,
            marginTop: 2,
          }}
          bgcolor="neutral.softHoverBg"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                maxWidth: { xs: '100%', sm: '48%' },
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" sx={{ marginBottom: 1 }}>
                  Business Volume
                </Typography>
                <Typography level="body1">
                  Volume: ${investmentAmount}
                </Typography>
                <Typography level="body1">This Month: $15,000</Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                maxWidth: { xs: '100%', sm: '48%' },
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" sx={{ marginBottom: 1 }}>
                  My Direct Clients
                </Typography>
                <Typography level="body1">
                  Total Clients: {totalClients}
                </Typography>
                <Typography level="body1">My Clients: 10</Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                maxWidth: { xs: '100%', sm: '48%' },
              }}
            >
              <CardContent>
                <Typography level="h5" fontWeight="lg" sx={{ marginBottom: 1 }}>
                  My Total Group
                </Typography>
                <Typography level="body1">Group Size: 450</Typography>
                <Typography level="body1">Growth Rate: 5%</Typography>
              </CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
              justifyContent: 'space-between',
              marginTop: 2,
            }}
          >
            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                maxWidth: '{ xs: "100%", sm: "48%" }',
                height: '110px',
                backgroundColor: '#2c3e50',
              }}
            >
              <CardContent></CardContent>
            </Card>
            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                maxWidth: { xs: '100%', sm: '48%' },
                backgroundColor: '#2c3e50',
              }}
            >
              <CardContent></CardContent>
            </Card>

            <Card
              sx={{
                flex: 1,
                padding: 2,
                borderRadius: 'md',
                boxShadow: 'sm',
                maxWidth: { xs: '100%', sm: '48%' },
                backgroundColor: '#2c3e50',
              }}
            >
              <CardContent></CardContent>
            </Card>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 3,
              backgroundColor: '#2C3E50',
              padding: 3,
              borderRadius: 'sm',
              color: '#FFFFFF',
              width: '100%',
              // maxWidth: 600,
            }}
          >
            <Typography
              level="body1"
              fontWeight="lg"
              sx={{ mb: 2, textAlign: 'center', fontSize: '22px' }}
            >
              Use Your Unique Referral link to invite Others!
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 'md',
                backgroundColor: '#1A2A33',
                padding: 1,
                width: '100%',
                border: '1px solid #98999a',
              }}
            >
              <Typography
                level="body1"
                sx={{
                  color: '#FFFFFF',
                  mr: 2,
                  textAlign: 'center',
                  wordBreak: 'break-all',
                }}
              >
                {referralLink}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCopyLink}
                sx={{ marginTop: { xs: 2, sm: 0 } }}
              >
                <ContentCopyIcon />
              </Button>
            </Box>
            <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
              <Typography level="body2" fontWeight="md" sx={{ mb: 1 }}>
                Scan the QR code below to access your referral link:
              </Typography>
              <Box
                sx={{
                  padding: 2,
                  borderRadius: '16px',
                  backgroundColor: '#fff',
                  boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'fit-content',
                  margin: '0 auto',
                }}
              >
                <QRCode value={referralLink} size={128} />
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{
              marginTop: 2,
              padding: "12px 20px",
              borderRadius: "10px",
              backgroundColor: "#F1F1F1",
              color: "#333",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              width: "100%",
              maxWidth: "300px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            " Market will be closed tomorrow.. Stay tune and follow us ."
          </Box> */}
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
              }}
            >
              <Typography level="h6" fontWeight="lg" sx={{ marginBottom: 2 }}>
                Notifications
              </Typography>
              {messages.length === 0 ? (
                <Typography>No messages available.</Typography>
              ) : (
                messages.map((msg) => (
                  <Card
                    key={msg.id}
                    sx={{ marginBottom: 2, backgroundColor: '#f5f5f5' }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ fontSize: '14px', marginBottom: '4px' }}
                      >
                        {new Date(
                          msg.createdAt?.seconds * 1000
                        ).toLocaleString()}
                      </Typography>
                      <Typography level="h6" fontWeight="lg">
                        {msg.title}
                      </Typography>
                      <Typography>{msg.message}</Typography>
                    </CardContent>
                  </Card>
                ))
              )}
            </Box>
          </Modal>
        </Sheet>
        <SupportSystem />
      </Box>
    </CssVarsProvider>
  );
}

export default Dashboard;
