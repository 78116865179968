import React, { useState, useEffect } from "react";
import { Box, Typography, Sheet, Table, Button, Modal } from "@mui/joy";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../theme";
import { useAuth } from "../context/AuthContext";
import { db } from "../firebase";

const LiveBalanceRequestList = () => {
  const { user } = useAuth();
  const userId = user.uid;
  const [members, setMembers] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(db, "liveAccountRequests")
        );
        const membersList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMembers(membersList);
      } catch (error) {
        console.error("Error fetching members from Firebase:", error);
        toast.error("Error fetching members from Firebase!");
      }
    };

    fetchMembers();
  }, [userId]);

  const updateMemberStatus = async (id, newStatus) => {
    try {
      const memberRef = doc(db, "liveAccountRequests", id);
      await updateDoc(memberRef, { status: newStatus });

      setMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === id ? { ...member, status: newStatus } : member
        )
      );

      toast.success(`Member ${id} status updated to ${newStatus}!`);
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status!");
    }
  };

  const handleAccept = (id) => {
    updateMemberStatus(id, "Accepted");
  };

  const handleReject = (id) => {
    updateMemberStatus(id, "Rejected");
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div style={{ backgroundColor: "#40474F", minHeight: "100vh" }}>
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2 }}>
          <Typography
            level="h2"
            color="text.primary"
            fontWeight="lg"
            sx={{
              mb: 2,
              color: "white",
              textAlign: "center", // Center align the title for better aesthetics
            }}
          >
            List of Members
          </Typography>
          <Sheet
            variant="soft"
            sx={{
              pt: 1,
              borderRadius: "sm",
              overflow: "hidden",
              boxShadow: 2, // Add subtle shadow for better contrast
            }}
          >
            <Box sx={{ overflowX: "auto" }}>
              <Table
                aria-label="member table"
                stripe="odd"
                hoverRow
                sx={{
                  minWidth: "600px", // Minimum width for the table
                  "& th, & td": {
                    whiteSpace: "nowrap",
                    padding: "8px 16px",
                  },
                  "& thead th": {
                    backgroundColor: "background.level1",
                    fontWeight: "bold",
                  },
                  "& tbody tr:hover": {
                    backgroundColor: "action.hover",
                  },
                  "& tbody": {
                    bgcolor: "background.surface",
                  },
                  // Responsive styles for mobile
                  "@media (max-width: 768px)": {
                    "& th, & td": {
                      fontSize: "14px", // Adjust font size for smaller screens
                      padding: "6px 12px", // Reduce padding
                    },
                    "& thead th": {
                      fontSize: "12px", // Smaller header font
                    },
                    // Hide some columns on smaller screens
                    "& td:nth-of-type(6), & th:nth-of-type(6)": {
                      display: "none",
                    },
                    "& td:nth-of-type(7), & th:nth-of-type(7)": {
                      display: "none",
                    },
                  },
                }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Broker Name</th>
                    <th>Payment Mode</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {members.map((member) => (
                    <tr key={member.id}>
                      <td>{member.id}</td>
                      <td>{member.brokerId}</td>
                      <td>{member.paymentModeId}</td>
                      <td>{member.status}</td>
                      <td>{member.tModeType}</td>
                      <td>
                        <img
                          src={member.imageUrl}
                          alt="Member"
                          onClick={() => handleImageClick(member.imageUrl)}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "4px",
                            cursor: "pointer", // Make the image clickable
                          }}
                        />
                      </td>
                      <td>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => handleAccept(member.id)}
                          sx={{
                            margin: "5px 0", // Spacing for buttons on mobile
                            width: "100%", // Make the buttons full width on mobile
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="outlined"
                          color="danger"
                          onClick={() => handleReject(member.id)}
                          sx={{
                            margin: "5px 0", // Spacing for buttons on mobile
                            width: "100%", // Make the buttons full width on mobile
                          }}
                        >
                          Reject
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Sheet>

          {/* Modal for displaying the full-size image */}
          <Modal open={!!selectedImage} onClose={closeModal}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "90vw", // Ensure modal image is responsive
              }}
            >
              <img
                src={selectedImage}
                alt="Enlarged Member"
                style={{
                  maxWidth: "90%",
                  maxHeight: "80vh",
                  borderRadius: "8px", // Add a border radius for aesthetics
                }}
              />
              <Button onClick={closeModal} color="danger" sx={{ mt: 2 }}>
                Close
              </Button>
            </Box>
          </Modal>
        </Box>
      </CssVarsProvider>
    </div>
  );
};

export default LiveBalanceRequestList;
