import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { db } from '../../firebase'; // Firebase configuration import
import { collection, getDocs, query, where } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';

function InvestmentReport() {
  const { user } = useAuth();
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log('settlements', settlements, user);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const currentUserUid = user.uid; // Replace this with the actual UID of the current user (e.g., fetched from auth)

        const q = query(collection(db, 'liveAccountRequests'));

        const querySnapshot = await getDocs(q);
        const settlementsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSettlements(settlementsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        toast.error('Error fetching data.');
        setLoading(false);
      }
    };

    fetchSettlements();
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#40474F',
        minHeight: '100vh',
        padding: '1rem',
      }}
    >
      <Typography
        level="h2"
        style={{ color: '#FFFFFF' }}
        fontWeight="lg"
        sx={{ fontSize: { xs: '1.5rem', md: '2rem' }, textAlign: 'center' }}
      >
        Investment Report
      </Typography>
      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            color: '#FFFFFF',
            backgroundColor: '#333',
            marginTop: '1rem',
          }}
        >
          <thead>
            <tr>
              <th style={tableHeaderStyle}>Date && Time</th>
              <th style={tableHeaderStyle}>Paymet Mode</th>
              <th style={tableHeaderStyle}>USDT</th>
            </tr>
          </thead>
          {/* <tbody>
            {settlements.map((settlement) => (
              <tr key={settlement.id}>
                <td style={tableCellStyle}>
                  {new Date(
                    settlement.timestamp.seconds * 1000
                  ).toLocaleString()}
                </td>
                <td style={tableCellStyle}>{settlement.id}</td>
                <td style={tableCellStyle}>{settlement.password}</td>
                <td style={tableCellStyle}>{settlement.amount}</td>
                <td style={tableCellStyle}>{settlement.balance}</td>
              </tr>
            ))}
          </tbody> */}
          <tbody>
            {settlements
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
              .map((item) => (
                <tr key={item.id}>
                  <td style={tableCellStyle}>
                    {item.createdAt
                      ? new Date(item.createdAt).toLocaleString() // Handle ISO string
                      : 'No timestamp available'}
                  </td>
                  <td style={tableCellStyle}>{item.paymentModeId}</td>
                  <td style={tableCellStyle}>{item.investmentAmount}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

// Table header and cell styling
const tableHeaderStyle = {
  padding: '10px',
  borderBottom: '2px solid #555',
  textAlign: 'left',
  backgroundColor: '#555',
};

const tableCellStyle = {
  padding: '10px',
  borderBottom: '1px solid #555',
  backgroundColor: '#bcbcbc',
  color: 'black',
};

export default InvestmentReport;
