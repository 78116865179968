import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
  RadioGroup,
  Radio,
} from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";
import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import { FormControlLabel } from "@mui/material";
// import background from "../../assets/background.webp"

const DirectMemberList = () => {
  const [formData, setFormData] = useState({
    introducerType: "", // Member code or name
    introducerCode: "", // For member code option
    introducerName: "", // For member name option
    joiningDate: "", // Joining date
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "http://ec2-13-201-97-103.ap-south-1.compute.amazonaws.com:8082/v1/user/save",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          mode: "cors",
        }
      );

      console.log("Data posted successfully:", response.data);
      toast.success("Data posted successfully!");

      // Reset form data after successful submission
      setFormData({
        introducerType: "",
        introducerCode: "",
        introducerName: "",
        joiningDate: "",
      });
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Error posting data!");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (event) => {
    if (event && event.target) {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    } else {
      console.error(
        "handleInputChange called with invalid event object:",
        event
      );
    }
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: "100vh",
          // backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#40474F",
        }}
        bgcolor="neutral.softHoverBg"
      >
        <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
          List of Direct Members
        </Typography>
        <Typography level="body-sm" style={{ color: "#FFFFFF" }} mb={2}>
          Join New Member from here
        </Typography>
        {/* <Sheet variant="outlined" sx={{ padding: 3, borderRadius: "sm" }}>
          <Box className="p-4 flex gap-2">
            <Box className="bg-sky-600 w-max p-2 rounded">
              <Typography level="h6" className="text-white">
                My Direct: 0
              </Typography>
            </Box>
            <Box className="bg-yellow-600 w-max p-2 rounded">
              <Typography level="h6" className="text-white">
                My Team: 0
              </Typography>
            </Box>
          </Box>
          <form onSubmit={handleSubmit}>
            <div className="flex">
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Introducer Type:</FormLabel>
                <RadioGroup
                  name="introducerType"
                  value={formData.introducerType}
                  onChange={handleInputChange}
                >
                  <div className="flex gap-2">
                    <FormControlLabel
                      value="code"
                      control={<Radio />}
                      label="Member Code"
                    />
                    <FormControlLabel
                      value="name"
                      control={<Radio />}
                      label="Member Name"
                    />
                  </div>
                </RadioGroup>
              </FormControl>

              {formData.introducerType === "code" && (
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Introducer Code:</FormLabel>
                  <Input
                    name="introducerCode"
                    value={formData.introducerCode}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              )}
              {formData.introducerType === "name" && (
                <FormControl sx={{ marginBottom: 2 }}>
                  <FormLabel>Introducer Name:</FormLabel>
                  <Input
                    name="introducerName"
                    value={formData.introducerName}
                    onChange={handleInputChange}
                    required
                  />
                </FormControl>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Joining Date:</FormLabel>
                <Input
                  type="date"
                  name="joiningDate"
                  value={formData.joiningDate}
                  onChange={handleInputChange}
                  required
                />
              </FormControl>
            </div>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                color="primary"
                variant="solid"
                sx={{ marginTop: 2 }}
                disabled={isSubmitting}
              >
                Search
              </Button>
            </Box>
          </form>
        </Sheet> */}
      </Box>
    </CssVarsProvider>
  );
};

export default DirectMemberList;
