import React from 'react';
import { Box, Typography, Sheet, Table } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import trc20Image from '../../assets/icons/qrcode.jpg';
import LiveBalanceRequest from './../liveBalanceRequest';

const Trcdeposit = () => {
  return (
    <div style={{ backgroundColor: '#40474F', minHeight: '100vh' }}>
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box sx={{ padding: 2, textAlign: 'center' }}>
          <Typography
            level="h2"
            color="text.primary"
            fontWeight="lg"
            sx={{ mb: 2 }}
          >
            Please Choose TRC20 network after scan. Otherwise your Payment will
            be cancelled
          </Typography>

          {/* Image Section */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              component="img"
              src={trc20Image} // Path to your image
              alt="TRC20 Network Instructions"
              sx={{
                display: 'block',
                maxWidth: '100%',
                height: 'auto',
                mb: 2,
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          </div>
          <Typography
            level="body1"
            color="text.secondary"
            sx={{ mt: 1, mb: 2 }}
          >
            TLvkKBCpJBbE2mCrvLs9AqagXFF1Gp6s6F
          </Typography>
          {/* <Typography
            level="h2"
            color="text.primary"
            fontWeight="lg"
            sx={{ mb: 2 }}
          >
            ***Please refresh after sending fund and Ckeck your transactions***
          </Typography> */}
        </Box>
        <LiveBalanceRequest />
      </CssVarsProvider>
    </div>
  );
};

export default Trcdeposit;
