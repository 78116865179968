// import React from 'react';

// function Contact() {
//   return (
//     <div style={styles.container}>
//       <h2 style={styles.heading}>Contact Us</h2>
      
//       <form style={styles.form}>
//         <label style={styles.label} htmlFor="name">Full Name</label>
//         <input
//           type="text"
//           name="name"
//           placeholder="Your Full Name"
//           style={styles.input}
//         />
        
//         <label style={styles.label} htmlFor="email">Email</label>
//         <input
//           type="email"
//           name="email"
//           placeholder="Your Email Address"
//           style={styles.input}
//         />
        
//         <label style={styles.label} htmlFor="message">Message</label>
//         <textarea
//           name="message"
//           placeholder="Write your message here"
//           style={styles.textarea}
//         />
        
//         <button type="submit" style={styles.button}>Submit</button>
//       </form>
//     </div>
//   );
// }

// const styles = {
//   container: {
//     maxWidth: '500px',
//     margin: '0 auto',
//     padding: '20px',
//     backgroundColor: '#f4f4f4',
//     borderRadius: '8px',
//     boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
//     textAlign: 'center',
//     width: '100%', 
//   },
//   heading: {
//     fontSize: '24px',
//     marginBottom: '20px',
//     color: '#333',
//   },
//   form: {
//     display: 'flex',
//     flexDirection: 'column',
//     gap: '15px',
//   },
//   label: {
//     fontSize: '16px',
//     color: '#333',
//     textAlign: 'left',
//     marginBottom: '5px',
//   },
//   input: {
//     padding: '10px',
//     fontSize: '16px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//   },
//   textarea: {
//     padding: '10px',
//     fontSize: '16px',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
//     resize: 'vertical',
//     minHeight: '100px',
//   },
//   button: {
//     padding: '10px',
//     fontSize: '16px',
//     fontWeight: 'bold',
//     color: '#fff',
//     backgroundColor: '#007bff',
//     border: 'none',
//     borderRadius: '4px',
//     cursor: 'pointer',
//   },
//   '@media (max-width: 600px)': {
//     container: {
//       padding: '10px',
//       boxShadow: 'none',
//     },
//     heading: {
//       fontSize: '20px',
//     },
//     input: {
//       padding: '8px',
//       fontSize: '14px',
//     },
//     textarea: {
//       padding: '8px',
//       fontSize: '14px',
//     },
//     button: {
//       padding: '8px',
//       fontSize: '14px',
//     },
//   },
// };

// export default Contact;


import React from 'react'

function Contact() {
  return (
    <>
      
    </>
  )
}

export default Contact
