import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WalletIcon from '@mui/icons-material/Wallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MessageIcon from '@mui/icons-material/Message';
import ColorSchemeToggle from './ColorSchemeToggle';
import { closeSidebar } from '../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { useEffect } from 'react';
import { useState } from 'react';

function Toggler({ defaultExpanded = false, renderToggle, children }) {
  const [open, setOpen] = React.useState(defaultExpanded);

  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [cretenuser, setCurentUser] = React.useState([]);
  const userEmail = user.email;
  const userRole = cretenuser.role;
  const [curentUserData, setCurentUserData] = useState([]);
  const tenantsCollection = collection(db, 'members');

  const checkEmailInMembers = async () => {
    try {
      // Step 1: Create a query to check if the email exists in the members collection
      const membersRef = collection(db, 'Tenants');
      const q = query(membersRef, where('email', '==', userEmail));
      const querySnapshot = await getDocs(q);

      // Step 2: Check if any documents match the query
      if (!querySnapshot.empty) {
        const memberData = querySnapshot.docs[0].data(); // Assuming one matching document
        setCurentUser(memberData);
        // Optionally store or display the member data
        return memberData;
      } else {
        console.log('No matching member found.');
        return null;
      }
    } catch (error) {
      console.error('Error checking member email:', error.message);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tenantsCollection) {
        const data = await fetchMembers(tenantsCollection);
        setCurentUserData(data[0]);

        console.log('111----------------->', data);
      }
    };
    fetchData(); // Call the async function
  }, []);

  async function fetchMembers(ref) {
    const snapshot = query(ref, where('email', '==', user?.email));
    const fetchData = await getDocs(snapshot);
    const results = [];

    fetchData.forEach((doc) => {
      const abc = { id: doc.id, ...doc.data() };
      results.push(abc);
    });

    if (results.length > 0) {
      return results;
    } else {
      return null;
    }
  }

  React.useEffect(() => {
    checkEmailInMembers();
  }, []);

  const handleLogout = async () => {
    await logout().then(() => {
      navigate('/');
    });
  };

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky', backgroundColor: '#575B60' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '240px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '260px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)', // Control the opacity of the Box
          backgroundColor: 'rgba(0, 0, 0, 0.3)', // This sets a 30% transparency for the overlay
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton variant="soft" color="primary" size="sm">
          <CurrencyBitcoinIcon />
        </IconButton>
        <Typography level="title-sm" sx={{ color: 'white' }}>
          {/* {user.email} */}
          {curentUserData?.name || user.email}
        </Typography>
        {/* <ColorSchemeToggle sx={{ ml: "auto" }} /> */}
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
            '&:hover': {
              backgroundColor: '#F13F41', // Hover color for list item buttons
            },
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <ListItem>
            <ListItemButton
              selected={location.pathname === '/home'}
              role="menuitem"
              component="a"
              onClick={() => {
                navigate('/home');
              }}
              sx={{
                '&:hover': {
                  backgroundColor: '#F13F41', // Red hover color
                },
                ...(location.pathname === '/home' && {
                  backgroundColor: '#F13F41', // Red color when selected
                }),
              }}
            >
              <HomeRoundedIcon sx={{ color: 'white' }} />
              <ListItemContent>
                <Typography
                  level="title-sm"
                  sx={{
                    color: 'white',
                    '&:hover': {
                      color: 'black',
                    },
                  }}
                >
                  Home
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  href="/dashboard"
                  onClick={() => setOpen(!open)}
                >
                  <AssignmentRoundedIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Dashboard
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton
                    selected={false}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        color: 'black',
                      },
                    }}
                  >
                    Dashboard
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    Business Summary
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  href="/myaccounts"
                  onClick={() => setOpen(!open)}
                >
                  <AccountCircleIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      My Accounts
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate('/mycripto');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    My Crypto address
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate('/myprofile');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    My Profile
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton selected={false}>My Bank</ListItemButton>
                </ListItem> */}
                <ListItem
                  onClick={() => {
                    navigate('/password');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    My Password
                  </ListItemButton>
                </ListItem>
                <ListItem
                  onClick={() => {
                    navigate('/kyc');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    KYC Permission
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          {/* <ListItem>
            <ListItemButton
              selected={false}
              role="menuitem"
              component="a"
              href="/orders"
            >
              <ShoppingCartRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">Orders</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem> */}
          {/* <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Equity Upload</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>Add Equity</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={false}>View Equity</ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem> */}
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <SupervisorAccountIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Member
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate('/addmember');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    Add Member
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/addmemberothercountry");
                    }}
                    selected={false}
                  >
                    Add Member (Other Country)
                  </ListItemButton>
                </ListItem> */}
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate('/memberlist');
                    }}
                    selected={false}
                    sx={{ color: 'white' }}
                  >
                    Member List
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate('/directmemberlist');
                    }}
                    selected={false}
                    sx={{ color: 'white' }}
                  >
                    Direct Member List
                  </ListItemButton>
                </ListItem>
                {/* <ListItem>
                  <ListItemButton
                    onClick={() => {
                      navigate("/levelview");
                    }}
                    selected={false}
                  >
                    Level View
                  </ListItemButton>
                </ListItem> */}
                {/* <ListItem>
                  <ListItemButton selected={false}>Tree View</ListItemButton>
                </ListItem> */}
                <ListItem>
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    Genealogy Tree
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          {/* <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon />
                  <ListItemContent>
                    <Typography level="title-sm">Refund Entry</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{ transform: open ? "rotate(180deg)" : "none" }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>
                    New Refund Entry
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={false}>
                    View Refund entry
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem> */}
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <AssignmentRoundedIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Withdrawal
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  onClick={() => {
                    navigate('/trcwithdrawal');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    TRC20 Withdrawal
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton
                  selected={false}
                  onClick={() => setOpen(!open)}
                  sx={{ color: 'white' }}
                >
                  <AssignmentRoundedIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Principal Withdrawal
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                <ListItem
                  // onClick={() => {
                  //   navigate("/trcdeposit");
                  // }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    Principal Withdrawal
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton selected={false} onClick={() => setOpen(!open)}>
                  <AssuredWorkloadIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Deposit
                    </Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon
                    sx={{
                      transform: open ? 'rotate(180deg)' : 'none',
                      color: 'white',
                    }}
                  />
                </ListItemButton>
              )}
            >
              <List sx={{ gap: 0.5 }}>
                {/* <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>INR Deposit</ListItemButton>
                </ListItem>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>UPI Deposit</ListItemButton>
                </ListItem>
                <ListItem sx={{ mt: 0.5 }}>
                  <ListItemButton selected={false}>INR Deposit</ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={false}>
                    INR UPI Deposit Report
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton selected={false}>TRX Report</ListItemButton>
                </ListItem> */}
                <ListItem
                  onClick={() => {
                    navigate('/trcdeposit');
                  }}
                  sx={{ mt: 0.5 }}
                >
                  <ListItemButton selected={false} sx={{ color: 'white' }}>
                    TRC20 Deposit
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>
          <ListItem
            onClick={() => {
              navigate('/mywallet');
            }}
          >
            <ListItemButton selected={false} role="menuitem" component="a">
              <WalletIcon sx={{ color: 'white' }} />
              <ListItemContent>
                <Typography
                  level="title-sm"
                  sx={{
                    color: 'white',
                    '&:hover': {
                      color: 'black',
                    },
                  }}
                >
                  My Wallet
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            onClick={() => {
              navigate('/live-balance-request');
            }}
          >
            <ListItemButton
              selected={false}
              role="menuitem"
              component="a"
              // href="/live-balance-request"
            >
              <AccountBalanceWalletIcon sx={{ color: 'white' }} />
              <ListItemContent>
                <Typography
                  level="title-sm"
                  sx={{
                    color: "white",
                    "&:hover": {
                      color: "black",
                    },
                  }}
                >
                  Account Balance Request
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem> */}
          {userRole === 'admin' && (
            <>
              <ListItem
                onClick={() => {
                  navigate('/live-balance-request-list');
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  // href="/live-balance-request"
                >
                  <AccountBalanceWalletIcon style={{ color: '#FFFFFF' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Account Balance Request List
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate('/oldsettlementview');
                }}
              >
                <ListItemButton selected={false} role="menuitem" component="a">
                  <AccountBalanceIcon sx={{ color: 'white' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Old Settlememt
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem nested>
                <Toggler
                  renderToggle={({ open, setOpen }) => (
                    <ListItemButton
                      selected={false}
                      onClick={() => setOpen(!open)}
                    >
                      <AssessmentIcon sx={{ color: 'white' }} />
                      <ListItemContent>
                        <Typography
                          level="title-sm"
                          sx={{
                            color: 'white',
                            '&:hover': {
                              color: 'black',
                            },
                          }}
                        >
                          Report
                        </Typography>
                      </ListItemContent>
                      <KeyboardArrowDownIcon
                        sx={{
                          transform: open ? 'rotate(180deg)' : 'none',
                          color: 'white',
                        }}
                      />
                    </ListItemButton>
                  )}
                >
                  <List sx={{ gap: 0.5 }}>
                    <ListItem
                      onClick={() => {
                        navigate('/investmentreport');
                      }}
                      sx={{ mt: 0.5 }}
                    >
                      <ListItemButton selected={false} sx={{ color: 'white' }}>
                        Investment Report
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        navigate('/withdrawalreport');
                      }}
                      sx={{ mt: 0.5 }}
                    >
                      <ListItemButton selected={false} sx={{ color: 'white' }}>
                        Withdrawal Report
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        navigate('/oldsettlementreport');
                      }}
                      sx={{ mt: 0.5 }}
                    >
                      <ListItemButton selected={false} sx={{ color: 'white' }}>
                        Old Settlememt Report
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Toggler>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate('/message');
                }}
              >
                <ListItemButton
                  selected={false}
                  role="menuitem"
                  component="a"
                  // href="/live-balance-request"
                >
                  <MessageIcon style={{ color: '#FFFFFF' }} />
                  <ListItemContent>
                    <Typography
                      level="title-sm"
                      sx={{
                        color: 'white',
                        '&:hover': {
                          color: 'black',
                        },
                      }}
                    >
                      Send Message
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </>
          )}
        </List>
        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2,
          }}
        >
          <ListItem
            onClick={() => {
              navigate('/support');
            }}
            sx={{ mt: 0.5, color: 'white' }}
          >
            <ListItemButton selected={false} sx={{ color: 'white' }}>
              <SupportRoundedIcon sx={{ color: 'white' }} />
              Support
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton
              onClick={() => navigate('/settings')}
              selected={false}
              sx={{ color: 'white' }}
            >
              <SettingsRoundedIcon sx={{ color: 'white' }} />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f13f41',
          borderRadius: '10px',
        }}
      >
        {/* <Avatar
          variant="outlined"
          size="sm"
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
        /> */}
        {/* <Box sx={{ minWidth: 0, flex: 1, color: "white" }}>
          <Typography level="title-sm" sx={{ color: "white" }}>
            {user.displayName}
          </Typography>
          <Typography level="body-xs" sx={{ color: "white" }}>
            {user.email}
          </Typography>
        </Box> */}
        <Box sx={{ minWidth: 0, color: 'white' }}>
          <Typography
            onClick={handleLogout}
            level="title-sm"
            sx={{ color: 'white' }}
          >
            Log-out
          </Typography>
        </Box>
        <IconButton
          onClick={handleLogout}
          size="sm"
          variant="plain"
          color="neutral"
        >
          <LogoutRoundedIcon sx={{ color: 'white' }} />
        </IconButton>
      </Box>
    </Sheet>
  );
}
