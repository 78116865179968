import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Container from "@mui/material/Container";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Button from "@mui/joy/Button";
import framesxTheme from "../theme";
import { useAuth } from "../context/AuthContext";
import Input from "@mui/joy/Input";
import { FormLabel } from "@mui/joy";
import SupportSystem from "./support/SupportSystem";

const Settings = () => {
  const { user, updateProfileInfo } = useAuth();
  const [displayName, setDisplayName] = React.useState(user?.displayName || "");
  const [mobileNumber, setMobileNumber] = React.useState(
    user?.mobileNumber || ""
  );
  const [email, setEmail] = React.useState(user?.email || "");
  const [isEditing, setIsEditing] = React.useState(false);

  const handleUpdateProfile = async () => {
    const profileInfo = {
      displayName: displayName,
      mobileNumber: mobileNumber,
    };
    try {
      await updateProfileInfo(profileInfo).then((res) => {
        console.log(res);
      });

      console.log("profileInfo", profileInfo);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating profile:", error.message);
    }
  };

  return (
    <div style={{ backgroundColor: "#40474F", minHeight: "100vh" }}>
      <ThemeProvider disableTransitionOnChange theme={framesxTheme}>
        <CssBaseline />
        <Container
          disableGutters
          maxWidth="sm"
          component="main"
          sx={{
            pt: 8,
            pb: 6,
            px: 2,
          }}
        >
          <div className="px-4">
            <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
              Profile Information
            </Typography>
          </div>
          {!isEditing ? (
            <div>
              <div
                className="py-2"
                style={{ marginTop: "20px", color: "#FFFFFF" }}
              >
                <FormLabel className="pb-2" style={{ color: "#FFFFFF" }}>
                  Display Name :
                </FormLabel>
                <Input type="text" disabled value={displayName} fullWidth />
              </div>
              <div className="py-2">
                <FormLabel className="pb-2" style={{ color: "#FFFFFF" }}>
                  Email :
                </FormLabel>
                <Input type="text" disabled value={email} fullWidth />
              </div>
              <div className="py-2 mb-3">
                <FormLabel className="pb-2" style={{ color: "#FFFFFF" }}>
                  Mobile Number :
                </FormLabel>
                <Input type="text" disabled value={mobileNumber} fullWidth />
              </div>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                sx={{
                  color: "white",
                  borderColor: "white",
                  width: "100%",
                }}
                onClick={() => setIsEditing(true)}
              >
                Edit Profile
              </Button>
            </div>
          ) : (
            <form>
              <div className="py-2">
                <FormLabel className="pb-2" style={{ color: "#FFFFFF" }}>
                  Display Name :
                </FormLabel>
                <Input
                  fullWidth
                  id="displayName"
                  placeholder="Display Name"
                  variant="outlined"
                  margin="normal"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>
              <div className="py-2">
                <FormLabel className="pb-2" style={{ color: "#FFFFFF" }}>
                  Email :
                </FormLabel>
                <Input
                  fullWidth
                  id="email"
                  placeholder="Email"
                  variant="outlined"
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="py-2 mb-3">
                <FormLabel className="pb-2" style={{ color: "#FFFFFF" }}>
                  Mobile Number :
                </FormLabel>
                <Input
                  fullWidth
                  id="mobileNumber"
                  placeholder="Mobile Number"
                  variant="outlined"
                  margin="normal"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </div>
              <div
                className="flex justify-between"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  color="danger"
                  sx={{ color: "white", borderColor: "white" }}
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  variant="solid"
                  color="success"
                  onClick={handleUpdateProfile}
                >
                  Update Profile
                </Button>
              </div>
            </form>
          )}
        </Container>
      </ThemeProvider>
      <SupportSystem />
    </div>
  );
};

export default Settings;
