import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Layout from '../layouts/Layout';
import { useAuth } from '../context/AuthContext';

import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../theme';
import { Box, Typography } from '@mui/joy';

export default function PrivateRoute() {
  const { token, user } = useAuth();
  return user ? (
    user ? (
      <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
        <Box component="main" className="MainContent">
          <Layout>
            <Outlet />
          </Layout>
        </Box>
      </CssVarsProvider>
    ) : (
      <span>loading...</span>
    )
  ) : (
    <Navigate to="/login" />
  );
}

// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import Layout from "../layouts/Layout";
// import { useAuth } from "../context/AuthContext";

// import { CssVarsProvider } from "@mui/joy/styles";
// import framesxTheme from "../theme";
// import { Box, Typography } from "@mui/joy";

// export default function PrivateRoute() {
//   const { token, user } = useAuth();
//   console.log("User data:", user);

//   if (!user) {
//     // Redirect to login if no user is found
//     return <Navigate to="/login" />;
//   }

//   if (!user.emailVerified) {
//     // Redirect to a verification page or show a message
//     return (
//       <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
//         <Box component="main" className="MainContent">
//           <Typography color="danger" textAlign="center" mt={4}>
//             Please verify your email address to continue.
//           </Typography>
//         </Box>
//       </CssVarsProvider>
//     );
//   }

//   return (
//     <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
//       <Box component="main" className="MainContent">
//         <Layout>
//           <Outlet />
//         </Layout>
//       </Box>
//     </CssVarsProvider>
//   );
// }
