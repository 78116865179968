import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import framesxTheme from '../../theme';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import SupportSystem from '../support/SupportSystem';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

function KycVerification() {
  const auth = getAuth();
  const db = getFirestore();
  const { user } = useAuth();
  console.log('user', user);

  const [selectedModalOption, setSelectedModalOption] = useState('');
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const storage = getStorage();
  const [formData, setFormData] = useState({
    idcardNumber: '',
    country: 'India',
    dob: '',
    idProofImageFront: '',
    idProofImageBack: '',
    idProofImageholding: '',
  });
  console.log('formdata', formData);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, 'Invalid mobile number')
      .required('Mobile Number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.string().required('State is required'),
  });

  // const handleInputChange = async (event) => {
  //   const { name, value, files } = event.target;

  //   if (name === 'front-image' || name === 'back-image') {
  //     const file = files[0];
  //     if (file) {
  //       try {
  //         const imageRef = ref(storage, `images/${name}/${file.name}`);
  //         await uploadBytes(imageRef, file);
  //         const downloadURL = await getDownloadURL(imageRef);

  //         // Update formData with the correct URL for front or back image
  //         setFormData((prevData) => ({
  //           ...prevData,
  //           [name === 'front-image' ? 'idProofImageFront' : 'idProofImageBack']:
  //             downloadURL,
  //         }));
  //         toast.success(
  //           `${
  //             name === 'front-image' ? 'Front' : 'Back'
  //           } Image uploaded successfully!`
  //         );
  //       } catch (error) {
  //         console.error('Error uploading image:', error);
  //         toast.error('Failed to upload image');
  //       }
  //     }
  //   } else {
  //     setFormData({ ...formData, [name]: value });
  //   }
  // };

  const handleInputChange = async (event) => {
    const { name, value, files } = event.target;
  
    if (name === 'front-image' || name === 'back-image' || name === 'holding-image') {
      const file = files[0];
      if (file) {
        try {
          // Determine the storage folder dynamically based on input name
          const imageRef = ref(storage, `images/${name}/${file.name}`);
          await uploadBytes(imageRef, file);
          const downloadURL = await getDownloadURL(imageRef);
  
          // Update formData with the correct URL for front, back, or holding image
          setFormData((prevData) => ({
            ...prevData,
            [name === 'front-image'
              ? 'idProofImageFront'
              : name === 'back-image'
              ? 'idProofImageBack'
              : 'idProofImageHolding']: downloadURL,
          }));
  
          toast.success(
            `${
              name === 'front-image'
                ? 'Front'
                : name === 'back-image'
                ? 'Back'
                : 'Holding'
            } Image uploaded successfully!`
          );
        } catch (error) {
          console.error('Error uploading image:', error);
          toast.error('Failed to upload image');
        }
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const userId = user ? user.uid : null;
      const userEmail = user ? user.email : null;
      console.log('userId', userId);

      // Only proceed if user is authenticated
      if (userId) {
        const kycData = {
          ...formData,
          idProofType: selectedModalOption,
          status: 'pending',
          userId,
          userEmail,
        };

        // Save data to Firestore
        await setDoc(doc(db, 'kycVerifications', userId), kycData);

        toast.success('Form submitted successfully!');
        // Clear the form data after successful submission
        setFormData({
          idcardNumber: '',
          country: 'India',
          dob: '',
          idProofImageFront: '',
          idProofImageBack: '',
          idProofImageholding:''
        });
        setSelectedModalOption('');
        setSelectedFile(null); // Clear the selected file if needed
      } else {
        toast.error('User not authenticated!');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error submitting form!');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{
          padding: 2,
          minHeight: '100vh',
          backgroundColor: '#40474F',
        }}
      >
        <Typography
          level="h2"
          style={{ color: '#FFFFFF', textAlign: 'center' }}
          fontWeight="lg"
          sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}
        >
          KYC Verification
        </Typography>
        <Sheet
          variant="soft"
          sx={{
            padding: { xs: 2, sm: 3 },
            borderRadius: 'sm',
            maxWidth: '100%',
            marginTop: 2,
            marginX: 'auto',
          }}
          bgcolor="neutral.softHoverBg"
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>ID Proof :</FormLabel>
                <select
                  id="id-proof"
                  className="px-2 py-2 border rounded bg-transparent border-gray-600 w-full"
                  value={selectedModalOption}
                  onChange={(e) => setSelectedModalOption(e.target.value)}
                >
                  <option value="" disabled>
                    Select Your ID Proof
                  </option>
                  <option value="Pan">PAN Card</option>
                  <option value="Adher">Aadhaar Card</option>
                  <option value="Voter">Voter Card</option>
                  <option value="National">National ID</option>
                  <option value="Driving Licence">Driving Licence</option>
                </select>
                {selectedModalOption && (
                  <Box sx={{ marginTop: 2 }}>
                    <FormLabel>{selectedModalOption} Number:</FormLabel>
                    <input
                      type="text"
                      placeholder={`Enter your ${selectedModalOption} Number`}
                      name="idcardNumber"
                      value={formData.idcardNumber}
                      onChange={handleInputChange}
                      className="px-2 py-2 border rounded bg-transparent border-gray-600 w-full"
                    />
                  </Box>
                )}
              </FormControl>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl sx={{ flex: 1 }}>
                  <label
                    htmlFor="front-image"
                    className="text-gray-600 font-semibold my-1"
                  >
                    ID Image [Front Side]
                  </label>
                  <input
                    type="file"
                    id="front-image"
                    name="front-image"
                    className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
                    onChange={handleInputChange}
                  />
                </FormControl>

                <FormControl sx={{ flex: 1 }}>
                  <label
                    htmlFor="back-image"
                    className="text-gray-600 font-semibold my-1"
                  >
                    ID Image [Back Side]
                  </label>
                  <input
                    type="file"
                    id="back-image"
                    name="back-image"
                    className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                  <label
                    htmlFor="back-image"
                    className="text-gray-600 font-semibold my-1"
                  >
                    Photo of you holding your ID
                  </label>
                  <input
                    type="file"
                    id="holding-image"
                    name="holding-image"
                    className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: 2,
                }}
              >
                <FormControl fullWidth>
                  <FormLabel style={{ color: 'black' }}>
                    Date of Birth:
                  </FormLabel>
                  <Input
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    sx={{
                      "& input[type='date']": {
                        padding: '10px',
                        fontSize: '1rem',
                        height: '38px',
                      },
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormLabel>Nationality :</FormLabel>
                  <select
                    className="px-2 py-2 border rounded bg-transparent border-gray-600 w-full"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="" disabled>
                      Select Nationality
                    </option>
                    {countries.map((country) => (
                      <option
                        key={country.name.common}
                        value={country.name.common}
                      >
                        {country.name.common}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <Typography color="error" variant="body2">
                      {errors.country}
                    </Typography>
                  )}
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="solid"
                  color="success"
                  sx={{ marginTop: 2, width: { xs: '100%', sm: 'auto' } }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Sheet>
      </Box>
      <SupportSystem />
    </CssVarsProvider>
  );
}

export default KycVerification;
