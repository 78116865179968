import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Button,
  Sheet,
  Input,
  Typography,
} from "@mui/joy";
import SupportSystem from "./SupportSystem";

const MainSupport = () => {
  const [category, setCategory] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedModalOption, setSelectedModalOption] = useState(""); // New state for selected slot option

  // Handle category change
  const handleCategoryChange = (event) => {
    if (event?.target) {
      setCategory(event.target.value);
    }
  };

  // Handle file selection change
  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    setSelectedFile(file);
  };

  // Handle slot selection change
  const handleSlotChange = (event) => {
    if (event?.target) {
      const slot = event.target.value;
      console.log("Selected slot:", slot);
      setSelectedModalOption(slot);
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Handle the form submission logic here
    console.log("Submitting form with category:", category);
    console.log("Selected file:", selectedFile);
  };

  return (
    <>
      <div style={{ backgroundColor: "#40474F", minHeight: "100vh" }}>
        <div className="px-4">
          <Typography level="h2" style={{ color: "#FFFFFF" }} fontWeight="lg">
            Support System
          </Typography>
        </div>
        <Sheet
          variant="soft"
          sx={{ padding: 3, borderRadius: "sm", maxWidth: 1500, marginTop: 2 }}
          bgcolor="neutral.softHoverBg"
        >
          {/* Category Selection */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Categorize :</FormLabel>
            <Select
              id="timeSlot"
              value={selectedModalOption}
              onChange={handleSlotChange}
              sx={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <MenuItem value="" disabled>
                Select an option
              </MenuItem>
              <MenuItem value="Income">Income Related</MenuItem>
              <MenuItem value="Profile">Profile Related</MenuItem>
              <MenuItem value="Top">Top-Up Related</MenuItem>
              <MenuItem value="Wallet">Wallet</MenuItem>
              <MenuItem value="Withdraw">Withdraw</MenuItem>
            </Select>
          </FormControl>

          {/* Description Input */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <FormLabel>Description:</FormLabel>
            <Input
              name="description"
              sx={{
                height: "100px",
                padding: "10px",
              }}
            />
          </FormControl>

          {/* File Upload */}
          <FormControl sx={{ marginTop: 2, minWidth: 200 }}>
            <label
              htmlFor="image"
              className="text-gray-600 font-semibold my-1 me-4"
            >
              Upload the support Image / Screenshot:
            </label>
            <input
              type="file"
              id="image"
              className="w-full h-10 border rounded-md focus:outline-none focus:border-blue-500 px-4 mb-4 drop-shadow"
              onChange={handleFileChange}
            />
          </FormControl>

          {/* Submit Button */}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          <SupportSystem />
        </Sheet>
      </div>
    </>
  );
};

export default MainSupport;
