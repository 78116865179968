import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  Button,
  Sheet,
} from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";

import { CssVarsProvider } from "@mui/joy/styles";
import framesxTheme from "../../theme";
import * as Yup from "yup";

const AddMemberOtherCountry = () => {
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Introducer Code is required"),
    introducerName: Yup.string().required("Introducer Name is required"),
    name: Yup.string().required("Name is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile Number is required"),
    emailid: Yup.string().email("Invalid email").required("Email is required"),
    country: Yup.string().required("Country is required"),
  });

  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    emailid: "",
    country: "",
    state: "",
    pan: "",
    code: "",
    introducerName: "",
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const response = await axios.post(
        "http://localhost:8080/v1/user/save",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
          },
          mode: "cors",
        }
      );

      console.log("Data posted successfully:", response.data);

      toast.success("Data posted successfully!");

      setFormData({
        code: "",
        introducerName: "",
        name: "",
        mobileNumber: "",
        emailid: "",
        country: "",
        state: "",
        pan: "",
      });
      setErrors({});
    } catch (error) {
      if (error.name === "ValidationError") {
        const yupErrors = {};
        error.inner.forEach((e) => {
          yupErrors[e.path] = e.message;
        });
        setErrors(yupErrors);
      } else {
        console.error("Error posting data:", error);
        toast.error("Error posting data!");
      }
    }
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    try {
      setFormData({
        ...formData,
        [name]: value,
      });

      await Yup.reach(validationSchema, name).validate(value);

      setErrors((prevErrors) => {
        return { ...prevErrors, [name]: "" };
      });
    } catch (error) {
      setErrors((prevErrors) => {
        return { ...prevErrors, [name]: error.message };
      });
    }
  };

  useEffect(() => {
    validationSchema.isValid(formData).then((valid) => {
      setIsValid(valid);
    });
  }, [formData, validationSchema]);

  return (
    <CssVarsProvider disableTransitionOnChange theme={framesxTheme}>
      <Box
        sx={{ padding: 2, minHeight: "100vh" }}
        bgcolor="neutral.softHoverBg"
      >
        <Typography level="h2" color="text.primary" fontWeight="lg">
          Add New Member (Other Country)
        </Typography>
        <Typography level="body-sm" color="text.secondary" mb={2}>
          Join New Member from here
        </Typography>
        <Sheet variant="outlined" sx={{ padding: 3, borderRadius: "sm" }}>
          <form onSubmit={handleSubmit}>
            <Typography level="h6" color="text.primary" mb={2}>
              Introducer Details
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Introducer Code:</FormLabel>
                <Input
                  name="code"
                  value={formData.code}
                  onChange={handleInputChange}
                  required
                />
                {errors.code && (
                  <Typography color="error" variant="body2">
                    {errors.code}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Introducer Name:</FormLabel>
                <Input
                  name="introducerName"
                  value={formData.introducerName}
                  onChange={handleInputChange}
                  required
                />
                {errors.introducerName && (
                  <Typography color="error" variant="body2">
                    {errors.introducerName}
                  </Typography>
                )}
              </FormControl>
            </div>

            <Typography level="h6" color="text.primary" mb={2}>
              Personal Details
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Name:</FormLabel>
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {errors.name && (
                  <Typography color="error" variant="body2">
                    {errors.name}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Mobile Number:</FormLabel>
                <Input
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  required
                />
                {errors.mobileNumber && (
                  <Typography color="error" variant="body2">
                    {errors.mobileNumber}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Email ID:</FormLabel>
                <Input
                  name="emailid"
                  value={formData.emailid}
                  onChange={handleInputChange}
                  required
                />
                {errors.emailid && (
                  <Typography color="error" variant="body2">
                    {errors.emailid}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ marginBottom: 2 }}>
                <FormLabel>Country:</FormLabel>
                <Input
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                />
                {errors.country && (
                  <Typography color="error" variant="body2">
                    {errors.country}
                  </Typography>
                )}
              </FormControl>
            </div>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                color="primary"
                variant="solid"
                sx={{ marginTop: 2 }}
                disabled={!isValid}
              >
                Create Member
              </Button>
            </Box>
          </form>
        </Sheet>
      </Box>
    </CssVarsProvider>
  );
};

export default AddMemberOtherCountry;
