/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import ArrowForward from "@mui/icons-material/ArrowForward";
import TwoSidedLayout from "../components/TwoSidedLayout";

export default function HeroLeft01() {
  return (
    <>
      <div style={{ backgroundColor: "#6B6E74" }}>
        <TwoSidedLayout>
          <Typography
            color="primary"
            fontSize="lg"
            fontWeight="lg"
            textColor="white"
          >
            Explore Opportunities
          </Typography>
          <Typography
            level="h1"
            fontWeight="xl"
            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
            textColor="white"
          >
            Leading  Institute for 
            stock market trading
          </Typography>
          <Typography fontSize="0.875rem" lineHeight="lg" textColor="#C0C0C0">
          Trading involves the buying and selling of financial assets like stocks, currencies, and commodities in various markets. Traders aim to profit from price fluctuations, utilizing strategies such as technical analysis, fundamental analysis, and risk management.
          </Typography>
          <Button
            size="lg"
            endDecorator={<ArrowForward fontSize="xl" />}
            sx={{
              backgroundColor: "#F13F41",
              color: "white",
              "&:hover": {
                backgroundColor: "#D63739", 
              },
            }}
          >
            Enquire Now
          </Button>
          <Typography textColor="#C0C0C0">
            Ready to dive in?{" "}
            <Link fontWeight="lg" textColor="white">
              Create an Account
            </Link>
          </Typography>
          <Typography
            level="body-xs"
            sx={{
              position: "absolute",
              top: "2rem",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {/*  Manager Pro */}
          </Typography>
        </TwoSidedLayout>
      </div>
    </>
  );
}
